import * as types from "./ValidatePolicyTypes";

//Replace action name and update action types
export const actionValidatePolicyInit = () => {
  return {
    type: types.VALIDATE_POLICY_INIT
  };
};

export const actionRequestValidatePolicy = (payload) => {
  const { polNum, agent, agencyLeader } = payload;
  return {
    type: types.VALIDATE_POLICY_REQUEST,
    payload: {
      polNum,
      agent,
      agencyLeader
    }
  };
};

export const actionReceiveValidatePolicySuccess = (data) => {
  return {
    type: types.VALIDATE_POLICY_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveValidatePolicyError = (data) => {
  return {
    type: types.VALIDATE_POLICY_RECEIVE_ERROR,
    data
  };
};

export const actionRequestValidateAddPolicy = (payload) => {
  const { polNum, agent, agencyLeader } = payload;
  return {
    type: types.VALIDATE_ADD_POLICY_REQUEST,
    payload: {
      polNum,
      agent,
      agencyLeader
    }
  };
};

export const actionRequestValidateAddPolicyInit = () => {
  return {
    type: types.VALIDATE_ADD_POLICY_INIT
  };
};

export const actionReceiveValidateAddPolicySuccess = (data) => {
  return {
    type: types.VALIDATE_ADD_POLICY_SUCCESS,
    data
  };
};

export const actionReceiveValidateAddPolicyError = (data) => {
  return {
    type: types.VALIDATE_ADD_POLICY_ERROR,
    data
  };
};
