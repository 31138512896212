import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetNotification = async () => {
  const auth = Cookies.get("token");
  const agentCode = Cookies.get("login_id");
  let data = {};
  let headers = {};
  if (agentCode) data["code"] = agentCode;
  if (auth)
    headers = {
      "x-ior-auth": auth
    };
  return await axiosInstance.post(`/common/getNotification`, data, {
    headers
  });
};

export default apiGetNotification;
