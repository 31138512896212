import { axiosInstance } from "@network/apis";

// Replace endpoint and change api name
const apiGetAdminAgentSearch = async (payload) => {
  const { code, agencyCode, authorization } = payload;
  const result = await axiosInstance.post(
    `/agent/search`,
    {
      code,
      agencyCode
    },
    {
      headers: {
        "x-ior-auth": authorization
      }
    }
  );
  return result;
};

export default apiGetAdminAgentSearch;
