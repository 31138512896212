import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./SubmitRecordAcknowledgementActions";
import apiSubmitRecordAcknowledgement from "./SubmitRecordAcknowledgementApis";
import * as Types from "./SubmitRecordAcknowledgementTypes";

// Replace with your sagas
export function* submitRecordAcknowledgement(action) {
    try {
        const response = yield call(apiSubmitRecordAcknowledgement, action.payload);
        yield put(Actions.actionReceiveSubmitRecordAcknowledgementSuccess(response.data));
    } catch (err) {
        yield put(Actions.actionReceiveSubmitRecordAcknowledgementError(err.response.data));
    }
}

export function* SubmitRecordAcknowledgement() {
    yield takeLatest(Types.REQUEST_SUBMIT_RECORDACKNOWLEDGEMENT, submitRecordAcknowledgement);
}
