import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ValidatePolicyAction";
import apiGetValidatePolicy from "./ValidatePolicyApis";
import * as Types from "./ValidatePolicyTypes";

// Replace with your sagas
export function* validatePolicy(action) {
  try {
    const response = yield call(apiGetValidatePolicy, action.payload);
    yield put(Actions.actionReceiveValidatePolicySuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveValidatePolicyError(err.response.data.message));
  }
}

export function* ValidatePolicy() {
  yield takeLatest(Types.VALIDATE_POLICY_REQUEST, validatePolicy);
}

export function* validateAddPolicy(action) {
  try {
    const response = yield call(apiGetValidatePolicy, action.payload);
    yield put(Actions.actionReceiveValidateAddPolicySuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveValidateAddPolicyError(err.response.data.message));
  }
}

export function* ValidateAddPolicy() {
  yield takeLatest(Types.VALIDATE_ADD_POLICY_REQUEST, validateAddPolicy);
}
