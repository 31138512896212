import { axiosInstance } from "@network/apis";

// Replace endpoint and change api name
const apiGetAdminAgentSearch = async (payload) => {
  const {
    code,
    agencyCode,
    status,
    appointmentDate,
    contractDate,
    startDate,
    endDate,
    modifiedDate,
    modifiedBy,
    grade,
    authorization
  } = payload;
  const result = await axiosInstance.post(
    `/agent/create`,
    {
      code,
      agencyCode,
      status,
      appointmentDate,
      contractDate,
      startDate,
      endDate,
      modifiedDate,
      modifiedBy,
      grade
    },
    {
      headers: {
        "x-ior-auth": authorization
      }
    }
  );
  return result;
};

export default apiGetAdminAgentSearch;
