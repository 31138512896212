import Cookies from "js-cookie";

// Service to check authentication for user and to signOut
const Auth = {
  signOut() {
    Cookies.remove("login_data");
    Cookies.remove("token");
    Cookies.remove("login_id");
    Cookies.remove("admin_id");
    Cookies.remove("rt");
  },
  isAuth() {
    return Cookies.get("login_data");
  },
  isValidate() {
    return Cookies.get("token");
  },
  isPrivateRouteValidated() {
    const token = Cookies.get("token");
    const rt = Cookies.get("rt");
    let validated = true;
    rt || token ? (validated = true) : (validated = false);
    return validated;
  },
  loginId() {
    return Cookies.get("login_id") ? Cookies.get("login_id") : "";
  },
  adminId() {
    return Cookies.get("admin_id") ? Cookies.get("admin_id") : "";
  },
  getRefreshToken() {
    return Cookies.get("rt");
  },
  storeUserInfoToCookie(data) {
    if (!data.access || !data.refresh) return null;
    const { access, refresh, loginID, adminID } = data;
    const accessExpires = new Date(access.expires);
    const refreshExpires = new Date(refresh.expires);
    Cookies.set("token", access.token, { expires: accessExpires });
    Cookies.set("rt", refresh.token, { expires: refreshExpires });
    if (loginID) Cookies.set("login_id", loginID, { expires: refreshExpires });
    if (adminID) Cookies.set("admin_id", adminID.trim(), { expires: refreshExpires });
    return data;
  }
};
export default Auth;
