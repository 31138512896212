import * as types from "./SubmitRecordAcknowledgementTypes";

const INITIAL_STATE = {
    success: false,
    error: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.REQUEST_SUBMIT_RECORDACKNOWLEDGEMENT_INIT:
            return INITIAL_STATE;
        case types.RECEIVE_SUBMIT_RECORDACKNOWLEDGEMENT_SUCCESS:
            return {
                ...action.data.data,
                success: true,
                error: false
            };
        case types.RECEIVE_SUBMIT_RECORDACKNOWLEDGEMENT_ERROR:
            return {
                ...action.data,
                success: false,
                error: true
            };

        default:
            return state;
    }
};
