import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./CreateCoachingAction";
import apiCreateCoaching from "./CreateCoachingApis";
import * as Types from "./CreateCoachingTypes";

// Replace with your sagas
export function* createCoaching(action) {
  try {
    const response = yield call(apiCreateCoaching, action.payload);
    yield put(Actions.actionReceiveCreateCoachingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveCreateCoachingError(err.response.data));
  }
}

export function* CreateCoaching() {
  yield takeLatest(Types.CREATE_COACHING_REQUEST, createCoaching);
}
