import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MeetingUpdateAction";
import apiUpdateMeeting from "./MeetingUpdateApis";
import * as Types from "./MeetingUpdateTypes";

// Replace with your sagas
export function* updateMeeting(action) {
  try {
    const response = yield call(apiUpdateMeeting, action.payload);
    yield put(Actions.actionReceiveUpdateMeetingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveUpdateMeetingError(err.response.data));
  }
}

export function* UpdateMeeting() {
  yield takeLatest(Types.UPDATE_MEETING_REQUEST, updateMeeting);
}
