import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./NotificationAction";
import apiGetNotification from "./NotificationApis";
import * as Types from "./NotificationTypes";

// Replace with your sagas
export function* getNotification() {
  try {
    const response = yield call(apiGetNotification);
    yield put(Actions.actionReceiveNotificationSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveNotificationError(err.response.data));
  }
}

export function* GetNotification() {
  yield takeLatest(Types.REQUEST_NOTIFICATION, getNotification);
}
