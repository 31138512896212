import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ArchiveJFVGetByIdActions";
import apiGetJfvById from "./ArchiveJFVGetByIdApis";
import * as Types from "./ArchiveJFVGetByIdTypes";

// Replace with your sagas
export function* jfvArchiveGetById(action) {
  try {
    const response = yield call(apiGetJfvById, action.id);
    yield put(Actions.actionRequestJfvArchiveByIdSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRequestJfvArchiveByIdError(err.response.data));
  }
}

export function* ArchiveJfvGetById() {
  yield takeLatest(Types.REQUEST_GET_JFV_ARCHIVE_BY_ID, jfvArchiveGetById);
}
