import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ArchiveMeetingExportAction";
import apiGetArchiveMeetingExport from "./ArchiveMeetingExportApis";
import * as Types from "./ArchiveMeetingExportTypes";

// Replace with your sagas
export function* archiveMeetingExport(action) {
  try {
    const response = yield call(apiGetArchiveMeetingExport, action.payload);
    yield put(Actions.actionRecArchiveMeetingExportSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRecArchiveMeetingExportError(err.response.data));
  }
}

export function* ArchiveMeetingExport() {
  yield takeLatest(Types.REQUEST_ARCHIVE_MEETING_EXPORT, archiveMeetingExport);
}
