import * as types from "./ArchiveMeetingTypes";

//Replace action name and update action types
export const actionReqArchiveMeetingInit = () => {
  return {
    type: types.REQUEST_ARCHIVE_MEETING_INIT
  };
};

export const actionReqArchiveMeeting = (payload) => {
  const { code, startDate, endDate, status, page, count } = payload;
  return {
    type: types.REQUEST_ARCHIVE_MEETING,
    payload: {
      code,
      startDate,
      endDate,
      status,
      page,
      count
    }
  };
};

export const actionRecArchiveMeetingSuccess = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_MEETING_SUCCESS,
    data
  };
};

export const actionRecArchiveMeetingError = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_MEETING_ERROR,
    data
  };
};
