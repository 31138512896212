import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./JFVOverviewAction";
import apiGetLeaderJFVOverview from "./JFVOverviewApis";
import * as Types from "./JFVOverviewTypes";

// Replace with your sagas
export function* getDataLeaderJFVOverview(action) {
  try {
    const response = yield call(apiGetLeaderJFVOverview, action.payload);
    yield put(Actions.actionReceiveLeaderJFVOverviewSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveLeaderJFVOverviewError(err.response.data));
  }
}

export function* LeaderJFVOverview() {
  yield takeLatest(Types.REQUEST_LEADER_JFV_OVERVIEW, getDataLeaderJFVOverview);
}
