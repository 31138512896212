import * as types from "./CreateJFVTypes";

const INITIAL_STATE = {
  success: false,
  error: false,
  data: {},
  score: 0,
  qna: []
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CREATE_JFV_INIT:
      return INITIAL_STATE;
    case types.CREATE_JFV_RECEIVE_SUCCESS:
      return {
        ...state,
        ...action.data,
        success: true,
        error: false
      };
    case types.CREATE_JFV_RECEIVE_ERROR:
      return {
        ...state,
        ...action.data,
        success: false,
        error: true
      };
    case types.CALCULATE_JFV_SCORE:
      const { qna } = action.payload;
      const { score } = action.payload;
      return {
        ...state,
        qna,
        score,
        success: false,
        error: false
      };
    case types.CALCULATE_JFV_SCORE_INIT: {
      const { qna } = action.payload;
      const { score } = action.payload;
      return {
        ...state,
        qna,
        score,
        success: false,
        error: false
      };
    }
    default:
      return state;
  }
};
