import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./PolicyDetailActions";
import apiGetPolicyDetail from "./PolicyDetailApis";
import * as Types from "./PolicyDetailTypes";

// Replace with your sagas
export function* policyDetail(action) {
  try {
    const response = yield call(apiGetPolicyDetail, action.data);
    yield put(Actions.actionReceivePolicyDetailSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceivePolicyDetailError(err.response));
  }
}

export function* PolicyDetail() {
  yield takeLatest(Types.REQUEST_POLICY_DETAIL, policyDetail);
}

export function* addPolicyDetail(action) {
  try {
    const response = yield call(apiGetPolicyDetail, action.policyNum);
    yield put(Actions.actionReceiveAddPolicyDetailSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveAddPolicyDetailError(err.response));
  }
}

export function* AddPolicyDetail() {
  yield takeLatest(Types.REQUEST_ADD_POLICY_DETAIL, addPolicyDetail);
}
