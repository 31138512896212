import * as Types from "./JFVGetArchiveAttachmentTypes";

//Replace action name and update action types
export const actionRequestDownloadArchiveAttachmentInit = () => {
  return {
    type: Types.REQUEST_DOWNLOAD_ARCHIVE_ATTACHMENT_INIT
  };
};

export const actionRequestDownloadArchiveAttachment = (payload) => {
  return {
    type: Types.REQUEST_DOWNLOAD_ARCHIVE_ATTACHMENT,
    payload
  };
};

export const actionReceiveDownloadArchiveAttachmentSuccess = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_ARCHIVE_ATTACHMENT_SUCCESS,
    data
  };
};

export const actionReceiveDownloadArchiveAttachmentError = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_ARCHIVE_ATTACHMENT_ERROR,
    data
  };
};
