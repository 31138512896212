import * as Types from "./CoachingUploadTypes";

//Replace action name and update action types
export const actionCoachingUploadInit = () => {
  return {
    type: Types.COACHING_UPLOAD_INIT
  };
};

export const actionRequestCoachingUpload = (payload) => {
  const { file, id } = payload;
  return {
    type: Types.COACHING_UPLOAD_REQUEST,
    payload: {
      file,
      id
    }
  };
};

export const actionReceiveCoachingUploadSuccess = (data) => {
  return {
    type: Types.COACHING_UPLOAD_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveCoachingUploadError = (data) => {
  return {
    type: Types.COACHING_UPLOAD_RECEIVE_ERROR,
    data
  };
};
