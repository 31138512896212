import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ArchiveJFVAction";
import apiGetArchiveJFV from "./ArchiveJFVApis";
import * as Types from "./ArchiveJFVTypes";

// Replace with your sagas
export function* archiveJFV(action) {
  try {
    const response = yield call(apiGetArchiveJFV, action.payload);
    yield put(Actions.actionRecArchiveJFVSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRecArchiveJFVError(err.response.data));
  }
}

export function* ArchiveJFV() {
  yield takeLatest(Types.REQUEST_ARCHIVE_JFV, archiveJFV);
}
