import * as types from "./GetAssignmentFromTypes";

//Replace action name and update action types
export const actionRequestGetAssignmentFromInit = () => {
  return {
    type: types.REQUEST_GET_ASSIGNMENT_FROM_INIT
  };
};

export const actionRequestGetAssignmentFrom = (payload) => {
  return {
    type: types.REQUEST_GET_ASSIGNMENT_FROM,
    payload
  };
};

export const actionReceiveGetAssignmentFromSuccess = (data) => {
  return {
    type: types.RECEIVE_GET_ASSIGNMENT_FROM_SUCCESS,
    data
  };
};

export const actionReceiveGetAssignmentFromError = (data) => {
  return {
    type: types.RECEIVE_GET_ASSIGNMENT_FROM_ERROR,
    data
  };
};
