import * as Types from "./CreateCoachingTypes";

/**
 * Function Coaching Actions
 * @function actionCreateCoachingInit @returns {object} - Action object with type 'CREATE_COACHING_INIT'
 * @function actionRequestCreateCoaching @returns {object} - Action object with type 'CREATE_COACHING_REQUEST' and payload
 * @function actionReceiveCreateCoachingSuccess @returns {object} - Action object with type 'CREATE_COACHING_RECEIVE_SUCCESS' and data
 * @function actionReceiveCreateCoachingError @returns {object} - Action object with type 'CREATE_COACHING_RECEIVE_ERROR' and data
 */

//Replace action name and update action types
export const actionCreateCoachingInit = () => {
  return {
    type: Types.CREATE_COACHING_INIT
  };
};

export const actionRequestCreateCoaching = (payload) => {
  const {
    type,
    twelveWeekCoaching,
    coachId,
    coachName,
    agencyCode,
    agencyName,
    coacheeId,
    coacheeName,
    date,
    startTime,
    endTime,
    topic,
    reviewPrevSession,
    activityManagement,
    agenda,
    keyCoachingPoints,
    actionPlan,
    coachFeedback,
    coacheeFeedback,
    status
  } = payload;
  return {
    type: Types.CREATE_COACHING_REQUEST,
    payload: {
      type,
      twelveWeekCoaching,
      coachId,
      coachName,
      agencyCode,
      agencyName,
      coacheeId,
      coacheeName,
      date,
      startTime,
      endTime,
      topic,
      reviewPrevSession,
      activityManagement,
      agenda,
      keyCoachingPoints,
      actionPlan,
      coachFeedback,
      coacheeFeedback,
      status
    }
  };
};

export const actionReceiveCreateCoachingSuccess = (data) => {
  return {
    type: Types.CREATE_COACHING_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveCreateCoachingError = (data) => {
  return {
    type: Types.CREATE_COACHING_RECEIVE_ERROR,
    data
  };
};
