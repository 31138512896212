import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiDeleteJFV = async (payload) => {
  const auth = Cookies.get("token");
  let config = {};
  if (auth) config = { headers: { "x-ior-auth": auth } };
  const {
    id
  } = payload;

  const result = await axiosInstance.delete(
    `/jfv/delete`,
    {
      data: {
        id
      },
      config
    }
  );
  return result;
};

export default apiDeleteJFV;
