import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ArchiveCoachingGetByIdActions";
import apiGetCoachingById from "./ArchiveCoachingGetByIdApis";
import * as Types from "./ArchiveCoachingGetByIdTypes";

// Replace with your sagas
export function* coachingArchiveGetById(action) {
  try {
    const response = yield call(apiGetCoachingById, action.id);
    yield put(Actions.actionRequestCoachingArchiveByIdSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRequestCoachingArchiveByIdError(err.response.data));
  }
}

export function* ArchiveCoachingGetById() {
  yield takeLatest(Types.REQUEST_GET_COACHING_ARCHIVE_BY_ID, coachingArchiveGetById);
}
