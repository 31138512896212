import React from "react";
import { Loading } from "@components/Icon/Icon";
import { theme } from "@utils/Constants";
import { LoaderStyled } from "./LoaderStyled";

const { colors } = theme;

const Loader = () => {
  return (
    <LoaderStyled>
      <div className="spinnerContainer">
        <Loading size="45px" color={colors.p1} />
        <span className="loaderText" data-testid="loader-text">
          Loading...
        </span>
      </div>
    </LoaderStyled>
  );
};

export default Loader;
