import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetManageJFV = async (payload) => {
  const auth = Cookies.get("token");
  let params = {};
  let config = {};
  const { code, startDate, endDate, page, count, showDeleted } = payload;
  if (page) params["p"] = page;
  if (count) params["c"] = count;
  if (auth) {
    config = {
      params,
      headers: {
        "x-ior-auth": auth
      }
    };
  }
  return await axiosInstance.post(`/jfv/search`, { code, startDate, endDate, showDeleted }, config);
};

export default apiGetManageJFV;
