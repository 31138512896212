import * as types from "./AssignmentListTypes";

//Replace action name and update action types
export const actionRequestAssignmentList = (agencyCode) => {
  return {
    type: types.REQUEST_ASSIGNMENT_LIST,
    agencyCode
  };
};

export const actionReceiveAssignmentListSuccess = (data) => {
  return {
    type: types.RECEIVE_ASSIGNMENT_LIST_SUCCESS,
    data
  };
};

export const actionReceiveAssignmentListError = (data) => {
  return {
    type: types.RECEIVE_ASSIGNMENT_LIST_ERROR,
    data
  };
};
