import * as types from "./RecordAcknowledgementListTypes";

const INITIAL_STATE = {
    data: [],
    success: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.RECEIVE_RECORDACKNOWLEDGEMENT_LIST_SUCCESS:
            return {
                ...action.data,
                success: true
            };
        case types.RECEIVE_RECORDACKNOWLEDGEMENT_LIST_ERROR:
            return {
                ...action.data,
                success: false
            };

        default:
            return state;
    }
};
