import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ManageCoachingAction";
import apiGetManageCoaching from "./ManageCoachingApis";
import * as Types from "./ManageCoachingTypes";

// Replace with your sagas
export function* manageCoaching(action) {
  try {
    const response = yield call(apiGetManageCoaching, action.payload);
    yield put(Actions.actionReceiveManageCoachingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveManageCoachingError(err));
  }
}

export function* ManageCoaching() {
  yield takeLatest(Types.MANAGE_COACHING_REQUEST, manageCoaching);
}
