import * as Types from "./MeetingDownloadDocFormTypes";

//Replace action name and update action types
export const actionRequestMeetingDownloadDocFormInit = () => {
  return {
    type: Types.REQUEST_DOWNLOAD_MEETING_DOC_FORM_INIT
  };
};

export const actionRequestMeetingDownloadDocForm = (id) => {
  return {
    type: Types.REQUEST_DOWNLOAD_MEETING_DOC_FORM,
    id
  };
};

export const actionReceiveMeetingDownloadDocFormSuccess = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_MEETING_DOC_FORM_SUCCESS,
    data
  };
};

export const actionReceiveMeetingDownloadDocFormError = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_MEETING_DOC_FORM_ERROR,
    data
  };
};
