import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetLeaderJFVOverview = async (payload) => {
  if (payload) {
    let { agentCode, agencyCode, startDate, endDate, page, count } = payload;
    let data = {};
    let params = {};

    if (page) params["p"] = page;
    if (count) params["c"] = count;
    if (!!agentCode) data["agentCode"] = agentCode;
    data["agencyCode"] = agencyCode;
    data["startDate"] = startDate;
    data["endDate"] = endDate;
    const auth = Cookies.get("token");
    const config = {
      params,
      headers: {
        "x-ior-auth": auth
      }
    };

    return await axiosInstance.post(`/jfv/overView`, data, config);
  }
};

export default apiGetLeaderJFVOverview;
