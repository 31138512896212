import Auth from "@utils/Auth";
import { axiosInstance } from "@network/apis";

// Replace endpoint and change api name
const apiPostLeaderAssignment = async (payload) => {
  const auth = Auth.isValidate();
  const config = {
    headers: {
      "x-ior-auth": auth
    }
  };
  const result = await axiosInstance.post(`/common/leaderAssignment`, payload, config);
  return result;
};

export default apiPostLeaderAssignment;

// startDate : "";
// endDate : "";
// alterAgentLeaderCode : "";
// alterAgencyCode : "";
// agentLeaderCode : "";
// agencyCode : "";
// type: ";
