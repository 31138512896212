import { lazy } from "react";

export const Home = lazy(() => import("@containers/Home/Home"));
export const Validate = lazy(() => import("@components/Validate/Validate"));
export const NotFound = lazy(() => import("@components/NotFound/NotFound"));
export const LoginFailed = lazy(() => import("@components/LoginFailed/LoginFailed"));
export const Logout = lazy(() => import("@components/Logout"));
export const Unauthorized = lazy(() => import("@components/Unauthorized/Unauthorized"));

// FSC
// FSC / T&C / COACHING MODULES
export const CoachingElectronicForm = lazy(() =>
  import("@containers/fsc/TnCRequirements/Coaching/ElectronicForm")
);
export const CoachingSuccess = lazy(() =>
  import("@containers/fsc/TnCRequirements/Coaching/CoachingSuccess")
);
export const CoachingSubmitted = lazy(() =>
  import("@containers/fsc/TnCRequirements/Coaching/CoachingSubmitted")
);
export const CoachingSaved = lazy(() =>
  import("@containers/fsc/TnCRequirements/Coaching/CoachingSaved")
);
export const CoachingDeleted = lazy(() =>
  import("@containers/fsc/TnCRequirements/Coaching/CoachingDeleted")
);
export const CoachingAcknowledgement = lazy(() =>
  import("@containers/fsc/TnCRequirements/Coaching/CoachingAcknowledgement")
);

// FSC / T&C / JFV MODULES
export const JFVElectronicForm = lazy(() =>
  import("@containers/fsc/TnCRequirements/JFV/ElectronicForm/JFVElectronicForm")
);
export const JFVSubmitted = lazy(() =>
  import("@containers/fsc/TnCRequirements/JFV/JFVSubmitted/JFVSubmitted")
);
export const JFVDeleted = lazy(() =>
  import("@containers/fsc/TnCRequirements/JFV/JFVDeleted/JFVDeleted")
);
export const JFVSaved = lazy(() => import("@containers/fsc/TnCRequirements/JFV/JFVSaved/JFVSaved"));
export const JFVSuccess = lazy(() =>
  import("@containers/fsc/TnCRequirements/JFV/JFVSuccess/JFVSuccess")
);
export const JFVAcknowledgement = lazy(() =>
  import("@containers/fsc/TnCRequirements/JFV/JFVAcknowledgement/JFVAcknowledgement")
);

// FSC / T&C / Meeting MODULES
export const MeetingElectronicForm = lazy(() =>
  import("@containers/fsc/TnCRequirements/Meeting/ElectronicForm/MeetingElectronicForm")
);
export const MeetingSubmitted = lazy(() =>
  import("@containers/fsc/TnCRequirements/Meeting/MeetingSubmitted/MeetingSubmitted")
);
export const MeetingSaved = lazy(() =>
  import("@containers/fsc/TnCRequirements/Meeting/MeetingSaved/MeetingSaved")
);
export const MeetingDeleted = lazy(() =>
  import("@containers/fsc/TnCRequirements/Meeting/MeetingDeleted/MeetingDeleted")
);
export const MeetingSuccess = lazy(() =>
  import("@containers/fsc/TnCRequirements/Meeting/MeetingSuccess/MeetingSuccess")
);

// FSC / OVERVIEW / RECORD MONITORING
export const RecordMonitoring = lazy(() =>
  import("@containers/fsc/Overview/RecordMonitoring/RecordMonitoring")
);

// FSC / OVERVIEW / LEADER OVERVIEW
export const LeaderOverviewMenus = lazy(() =>
  import("@containers/fsc/Overview/LeaderOverview/LeaderOverviewMenus/LeaderOverviewMenus")
);
export const LeaderCoachingOverview = lazy(() =>
  import("@containers/fsc/Overview/LeaderOverview/CoachingOverview/CoachingOverview")
);
export const LeaderMeetingOverview = lazy(() =>
  import("@containers/fsc/Overview/LeaderOverview/MeetingOverview/MeetingOverview")
);
export const LeaderJFVOverview = lazy(() =>
  import("@containers/fsc/Overview/LeaderOverview/JFVOverview/JFVOverview")
);

// FSC / OVERVIEW / OVERVIEW DETAILED
export const CoachingOverviewDetailed = lazy(() =>
  import(
    "@containers/fsc/Overview/LeaderOverview/CoachingOverviewDetailed/CoachingOverviewDetailed"
  )
);
export const MeetingOverviewDetailed = lazy(() =>
  import("@containers/fsc/Overview/LeaderOverview/MeetingOverviewDetailed/MeetingOverviewDetailed")
);

// FSC / OVERVIEW / E-ACKNOWLEDGEMENT
export const Eacknowledgement = lazy(() =>
  import("@containers/fsc/Overview/Eacknowledgement/Eacknowledgement")
);
export const SubmitAssignmentSuccess = lazy(() =>
  import("@containers/fsc/Overview/Eacknowledgement/AssignmentSuccess/AssignmentSuccess")
);

//FSC/ ADDITIONAL / RECORD ACKNOWLEDGEMENT
export const RecordAcknowledgement = lazy(() =>
  import("@containers/fsc/Overview/RecordAcknowledgement/RecordAcknowledgement")
);

// FSC / ADDITIONAL / ASSIGNMENT
export const Assignment = lazy(() => import("@containers/fsc/Additional/Assignment/Assignment"));
export const AssignmentHistory = lazy(() =>
  import("@containers/fsc/Additional/AssignmentHistory/AssignmentHistory")
);
export const AssignmentSuccess = lazy(() =>
  import("@containers/fsc/Additional/AssignmentSuccess/AssignmentSuccess")
);

// FSC / ARCHIVE
export const ArchiveCoaching = lazy(() =>
  import("@containers/fsc/Archive/CoachingArchive/CoachingArchive")
);
export const ArchiveCoachingDetailed = lazy(() =>
  import("@containers/fsc/Archive/CoachingArchive/CoachingArchiveDetailed")
);
export const ArchiveJFV = lazy(() => import("@containers/fsc/Archive/JFVArchive/JFVArchive"));
export const ArchiveJfvDetailed = lazy(() =>
  import("@containers/fsc/Archive/JFVArchive/JFVArchiveDetailed")
);
export const ArchiveMeeting = lazy(() =>
  import("@containers/fsc/Archive/MeetingArchive/MeetingArchive")
);
export const ArchiveMeetingDetailed = lazy(() =>
  import("@containers/fsc/Archive/MeetingArchive/MeetingArchiveDetailed")
);

// STAFF VIEW
export const StaffViewLandingPage = lazy(() =>
  import("@containers/admin/StaffViewLandingPage/StaffViewLandingPage")
);
// STAFF VIEW / OVERVIEW
export const AdminCoachingOverview = lazy(() =>
  import("@containers/admin/AdminOverview/CoachingOverview/CoachingOverview")
);
export const AdminJFVOverview = lazy(() =>
  import("@containers/admin/AdminOverview/JFVOverview/JFVOverview")
);
export const AdminMeetingOverview = lazy(() =>
  import("@containers/admin/AdminOverview/MeetingOverview/MeetingOverview")
);

// ADMIN PORTAL MODULES
export const AdminPortal = lazy(() => import("@containers/admin/AdminPortal/AdminPortal"));
export const PortalMonitoring = lazy(() =>
  import("@containers/admin/BSC/PortalMonitoring/PortalMonitoring")
);
export const BSCGradeSheet = lazy(() =>
  import("@containers/admin/BSC/BSCGradeSheet/BSCGradeSheet")
);
export const BCSUpload = lazy(() => import("@containers/admin/BSC/UploadBSC/UploadBSC"));

// TEMPORARY
// export const Login = lazy(() => import("@containers/temp/Login"));
