import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./JFVDownloadDocFormAction";
import apiJfvDownloadDocForm from "./JFVDownloadDocFormApis";
import * as Types from "./JFVDownloadDocFormTypes";

// Replace with your sagas
export function* jfvDownloadDocForm(action) {
  try {
    const response = yield call(apiJfvDownloadDocForm, action.id);
    yield put(Actions.actionReceiveJfvDownloadDocFormSuccess(response));
  } catch (err) {
    yield put(Actions.actionReceiveJfvDownloadDocFormError(err.response));
  }
}

export function* JfvDownloadDocForm() {
  yield takeLatest(Types.REQUEST_DOWNLOAD_JFV_DOC_FORM, jfvDownloadDocForm);
}
