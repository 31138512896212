import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./AssignmentListAction";
import apiGetAssignmentList from "./AssignmentListApis";
import * as Types from "./AssignmentListTypes";

// Replace with your sagas
export function* getAssignmetList(action) {
  try {
    const response = yield call(apiGetAssignmentList, action.agencyCode);
    yield put(Actions.actionReceiveAssignmentListSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveAssignmentListError(err.response.data));
  }
}

export function* AssignmentList() {
  yield takeLatest(Types.REQUEST_ASSIGNMENT_LIST, getAssignmetList);
}
