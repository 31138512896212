import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiUpdateCoaching = async (payload) => {
  const auth = Cookies.get("token");
  let config = {};
  if (auth) config = { headers: { "x-ior-auth": auth } };
  const {
    id,
    type,
    // twelveWeekCoaching,
    coachId,
    coachName,
    agencyCode,
    agencyName,
    coacheeId,
    coacheeName,
    date,
    startTime,
    endTime,
    topic,
    reviewPrevSession,
    activityManagement,
    agenda,
    keyCoachingPoints,
    actionPlan,
    coachFeedback,
    coacheeFeedback,
    status,
    deletedBy,
    deletedByName
  } = payload;

  const result = await axiosInstance.post(
    `/coaching/update`,
    {
      id,
      type,
      "12weekCoaching": false,
      coachId,
      coachName,
      agencyCode,
      agencyName,
      coacheeId,
      coacheeName,
      date,
      startTime,
      endTime,
      topic,
      reviewPrevSession,
      activityManagement,
      agenda,
      keyCoachingPoints,
      actionPlan,
      coachFeedback,
      coacheeFeedback,
      status,
      deletedBy,
      deletedByName
    },
    config
  );
  return result;
};

export default apiUpdateCoaching;
