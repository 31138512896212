import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiOwnJFVUpload = async (payload) => {
  const auth = Cookies.get("token");
  let config = {};
  if (auth) {
    config = {
      headers: {
        "x-ior-auth": auth,
        "Content-Type": "multipart/form-data"
      }
    };
  }
  let data = new FormData();
  data.append("file", payload.file);
  data.append("id", payload.id);
  const result = await axiosInstance.post(`/jfv/uploadForm`, data, config);
  return result;
};

export default apiOwnJFVUpload;
