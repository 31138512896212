import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MeetingDownloadDocFormAction";
import apiMeetingDownloadDocForm from "./MeetingDownloadDocFormApis";
import * as Types from "./MeetingDownloadDocFormTypes";

// Replace with your sagas
export function* meetingDownloadDocForm(action) {
  try {
    const response = yield call(apiMeetingDownloadDocForm, action.id);
    yield put(Actions.actionReceiveMeetingDownloadDocFormSuccess(response));
  } catch (err) {
    yield put(Actions.actionReceiveMeetingDownloadDocFormError(err.response));
  }
}

export function* MeetingDownloadDocForm() {
  yield takeLatest(Types.REQUEST_DOWNLOAD_MEETING_DOC_FORM, meetingDownloadDocForm);
}
