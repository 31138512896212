import Auth from "@utils/Auth";
import { axiosInstance } from "@network/apis";

// Replace endpoint and change api name
const apiSubmitAssignment = async (payload) => {
  const auth = Auth.isValidate();
  const config = {
    headers: {
      "x-ior-auth": auth
    }
  };
  const result = await axiosInstance.post(`/common/submitAssignment`, payload, config);
  return result;
};

export default apiSubmitAssignment;
