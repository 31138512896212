import * as types from "./SubmitAssignmentTypes";

//Replace action name and update action types
export const actionRequestSubmitAssignmentInit = () => {
  return {
    type: types.REQUEST_SUBMIT_ASSIGNMENT_INIT
  };
};

export const actionRequestSubmitAssignment = (payload) => {
  const {
    dteEffFrom,
    dteEffTo,
    dteAssignDate,
    txtAgtLeader,
    txtAgyLeader,
    txtAlterAgtLeader,
    txtAlterAgyLeader,
    status,
    type
  } = payload;
  return {
    type: types.REQUEST_SUBMIT_ASSIGNMENT,
    payload: {
      dteEffFrom,
      dteEffTo,
      dteAssignDate,
      txtAgtLeader,
      txtAgyLeader,
      txtAlterAgtLeader,
      txtAlterAgyLeader,
      status,
      type
    }
  };
};

export const actionReceiveSubmitAssignmentSuccess = (data) => {
  return {
    type: types.RECEIVE_SUBMIT_ASSIGNMENT_SUCCESS,
    data
  };
};

export const actionReceiveSubmitAssignmentError = (data) => {
  return {
    type: types.RECEIVE_SUBMIT_ASSIGNMENT_ERROR,
    data
  };
};
