import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import icon from "../../assets/images/svg/icons.svg";
import { dash, rotation } from "../Animation/Animations";

export const IconStatic = (props) => {
  const { name, size, color, ...attr } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="black"
      className={`icon-${size} icon-64 hide-on-fallback`}
      {...attr}
    >
      <use xlinkHref={`${icon}#${name}`} style={{ fill: color }} />
    </svg>
  );
};

IconStatic.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["xs", "s", "m", "l", "xl"]),
  color: PropTypes.string
};

IconStatic.defaultProps = {
  size: "s",
  color: "white"
};

/**
 * ICON FONT
 */

const sizeMapper = {
  xs: "1em",
  s: "1.5em",
  m: "2em",
  l: "2.5em",
  xl: "3em"
};
const Component = styled.i`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size && sizeMapper[props.size]};
`;
export const Icon = (props) => {
  const { name, className, ...attr } = props;
  return <Component className={`icon-${name} ${className}`} {...attr} />;
};

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["xs", "s", "m", "l", "xl"]),
  color: PropTypes.string
};

Icon.defaultProps = {
  className: "",
  size: "s",
  color: "#000"
};

const SVG = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  path,
  circle {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: ${dash} 3s ease-in-out forwards;
      stroke: ${(props) => props.color};
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      stroke: ${(props) => props.color};
    }
  }
  path {
    animation-delay: 1s;
  }
`;

export const IconSuccess = ({ color, size, ...attr }) => {
  return (
    <SVG color={color} size={size} {...attr}>
      <svg viewBox="0 0 50 50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
        <path
          id="svg_3"
          d="m9.934063,19.383215l6.315882,7.32741c4.605331,-4.47375 9.210662,-8.9475 13.815992,-13.42125"
          fillOpacity="null"
          strokeOpacity="null"
          strokeWidth="3"
          fill="none"
          transform="translate(5, 5)"
        />
        <circle fill="none" strokeWidth="3" strokeMiterlimit="10" cx="25" cy="25" r="22" />
      </svg>
    </SVG>
  );
};

IconSuccess.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
IconSuccess.defaultProps = {
  color: "#b2cd3a",
  size: "100px"
};

const LoadingIcon = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  box-sizing: border-box;
  animation: 1s linear infinite ${rotation()};
  animation-delay: 0.3s;
  border: 2px solid #dbdad4;
  border-right: 2px solid ${(props) => props.color};
  background: transparent;
  border-radius: 100%;
  padding: 0;
  .child {
    display: none;
  }
`;

export const Loading = ({ color, size, ...attr }) => {
  return <LoadingIcon color={color} size={size} {...attr} />;
};

Loading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};
Loading.defaultProps = {
  color: "#b2cd3a",
  size: "100px"
};
