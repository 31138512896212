import * as types from "./MenuTypes";

//Replace action name and update action types
export const actionRequestGetMenuInit = () => {
  return {
    type: types.REQUEST_GET_MENU_INIT
  };
};

export const actionRequestGetMenu = () => {
  return {
    type: types.REQUEST_GET_MENU
  };
};

export const actionReceiveGetMenuSuccess = (data) => {
  return {
    type: types.RECEIVE_GET_MENU_SUCCESS,
    data
  };
};

export const actionReceiveGetMenuError = (data) => {
  return {
    type: types.RECEIVE_GET_MENU_ERROR,
    data
  };
};
