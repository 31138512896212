import * as Types from "./ArchiveJFVDownloadDocTypes";

//Replace action name and update action types
export const actionReqArchiveJFVDownloadDocInit = () => {
  return {
    type: Types.REQUEST_ARCHIVE_JFV_DOWNLOAD_DOC_INIT
  };
};

export const actionReqArchiveJFVDownloadDoc = (id) => {
  return {
    type: Types.REQUEST_ARCHIVE_JFV_DOWNLOAD_DOC,
    id
  };
};

export const actionRecArchiveJFVDownloadDocSuccess = (data) => {
  return {
    type: Types.RECEIVE_ARCHIVE_JFV_DOWNLOAD_DOC_SUCCESS,
    data
  };
};

export const actionRecArchiveJFVDownloadDocError = (data) => {
  return {
    type: Types.RECEIVE_ARCHIVE_JFV_DOWNLOAD_DOC_ERROR,
    data
  };
};
