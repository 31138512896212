import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ArchiveMeetingGetByIdActions";
import apiGetMeetingById from "./ArchiveMeetingGetByIdApis";
import * as Types from "./ArchiveMeetingGetByIdTypes";

// Replace with your sagas
export function* meetingArchiveGetById(action) {
  try {
    const response = yield call(apiGetMeetingById, action.id);
    yield put(Actions.actionRequestMeetingArchiveByIdSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRequestMeetingArchiveByIdError(err.response.data));
  }
}

export function* ArchiveMeetingGetById() {
  yield takeLatest(Types.REQUEST_GET_MEETING_ARCHIVE_BY_ID, meetingArchiveGetById);
}
