import * as types from "./JFVSubmittedTypes";

const INITIAL_STATE = {
  success: false,
  data: {}
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.JFV_SUBMITTED_INIT:
      return INITIAL_STATE;
    case types.JFV_SUBMITTED_PUSH:
      return {
        data: action.payload,
        success: true
      };

    default:
      return state;
  }
};
