import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./CoachingUpdateAction";
import apiUpdateCoaching from "./CoachingUpdateApis";
import * as Types from "./CoachingUpdateTypes";

// Replace with your sagas
export function* updateCoaching(action) {
  try {
    const response = yield call(apiUpdateCoaching, action.payload);
    yield put(Actions.actionReceiveUpdateCoachingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveUpdateCoachingError(err.response.data));
  }
}

export function* UpdateCoaching() {
  yield takeLatest(Types.UPDATE_COACHING_REQUEST, updateCoaching);
}
