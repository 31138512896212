import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./CoachingDeleteAction";
import apiDeleteCoaching from "./CoachingDeleteApis";
import * as Types from "./CoachingDeleteTypes";

// Replace with your sagas
export function* deleteCoaching(action) {
  try {
    const response = yield call(apiDeleteCoaching, action.payload);
    yield put(Actions.actionReceiveDeleteCoachingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveDeleteCoachingError(err.response.data));
  }
}

export function* DeleteCoaching() {
  yield takeLatest(Types.DELETE_COACHING_REQUEST, deleteCoaching);
}
