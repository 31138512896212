import * as types from "./PolicyDetailTypes";

const INITIAL_STATE = {
  data: {},
  dataAdd: {},
  success: false,
  successAdd: false,
  error: false,
  errorAdd: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.INIT_POLICY_DETAIL:
      return INITIAL_STATE;
    case types.INIT_ADD_POLICY_DETAIL:
      return {
        ...state,
        dataAdd: {},
        successAdd: false,
        errorAdd: false
      };
    case types.RECEIVE_SUCCESS_POLICY_DETAIL:
      return {
        ...state,
        data: action.data.data,
        success: true,
        error: false
      };
    case types.RECEIVE_ERROR_POLICY_DETAIL:
      return {
        ...state,
        data: action.data,
        success: false,
        error: true
      };
    case types.RECEIVE_SUCCESS_ADD_POLICY_DETAIL:
      return {
        ...state,
        dataAdd: action.data.data,
        successAdd: true,
        errorAdd: false
      };
    case types.RECEIVE_ERROR_ADD_POLICY_DETAIL:
      return {
        ...state,
        dataAdd: action.data,
        successAdd: false,
        errorAdd: true
      };
    default:
      return state;
  }
};
