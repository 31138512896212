import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ValidateAction";
import { apiGetValidateToken } from "./ValidateApis";
import * as Types from "./ValidateTypes";

// Replace with your sagas
export function* getValidateToken(action) {
  try {
    const response = yield call(apiGetValidateToken, action.data);
    yield put(Actions.actionReceiveValidateToken(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* ValidateToken() {
  yield takeLatest(Types.GET_REQUEST_VALIDATE_TOKEN, getValidateToken);
}
