import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";
// import { mockedResult } from "./JFVGetArchiveAttachment.mock";

// Replace endpoint and change api name
const apiDownloadArchiveAttachmentForm = async (payload) => {
  const { seqId, moduleType } = payload;
  const auth = Cookies.get("token");
  let config;
  if (auth) {
    config = {
      headers: {
        "x-ior-auth": auth
      }
    };
  }

  return await axiosInstance.post(
    `/jfv/getArchiveAttachment`,
    {
      int_seqno_mst: seqId,
      moduleType
    },
    config
  );
};

export default apiDownloadArchiveAttachmentForm;
