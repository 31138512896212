import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./AdminInfoAction";
import apiGetAdminInfo from "./AdminInfoApis";
import * as Types from "./AdminInfoTypes";

// Replace with your sagas
export function* getDataAdminInfo(action) {
  try {
    const response = yield call(apiGetAdminInfo, action.code);
    yield put(Actions.actionReceiveAdminInfoSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveAdminInfoError(err));
  }
}

export function* AdminInfo() {
  yield takeLatest(Types.REQUEST_ADMIN_INFO, getDataAdminInfo);
}
