import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./GetAssignmentToAction";
import apiGetAssignmentTo from "./GetAssignmentToApis";
import * as Types from "./GetAssignmentToTypes";

// Replace with your sagas
export function* getAssignmentTo(action) {
  try {
    const response = yield call(apiGetAssignmentTo, action.payload);
    yield put(Actions.actionReceiveGetAssignmentToSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveGetAssignmentToError(err.response.data));
  }
}

export function* GetAssignmentTo() {
  yield takeLatest(Types.REQUEST_GET_ASSIGNMENT_TO, getAssignmentTo);
}
