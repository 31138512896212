import * as Types from "./JFVUpdateTypes";

//Replace action name and update action types
export const actionUpdateJFVInit = () => {
  return {
    type: Types.UPDATE_JFV_INIT
  };
};

export const actionRequestUpdateJFV = (payload) => {
  return {
    type: Types.UPDATE_JFV_REQUEST,
    payload
  };
};

export const actionReceiveUpdateJFVSuccess = (data) => {
  return {
    type: Types.UPDATE_JFV_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveUpdateJFVError = (data) => {
  return {
    type: Types.UPDATE_JFV_RECEIVE_ERROR,
    data
  };
};
