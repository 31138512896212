import * as types from "./ArchiveCoachingTypes";

//Replace action name and update action types
export const actionReqArchiveCoachingInit = () => {
  return {
    type: types.REQUEST_ARCHIVE_COACHING_INIT
  };
};

export const actionReqArchiveCoaching = (payload) => {
  const { code, startDate, endDate, status, page, count } = payload;
  return {
    type: types.REQUEST_ARCHIVE_COACHING,
    payload: {
      code,
      startDate,
      endDate,
      status,
      page,
      count
    }
  };
};

export const actionRecArchiveCoachingSuccess = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_COACHING_SUCCESS,
    data
  };
};

export const actionRecArchiveCoachingError = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_COACHING_ERROR,
    data
  };
};
