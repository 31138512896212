import React from "react";
import { Modal } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

const ModalSessionExpired = () => {
  return (
    <Modal
      title={`Session Expired`}
      centered
      visible={true}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={() => {
        window.open("https://www.aia.com.sg/", "_self");
      }}
    >
      <div className="box-message">
        <div className="clock-icon">
          <ClockCircleOutlined />
        </div>
        <div className="text">
          <p>Your session has expired.</p>
          <p>Please login again into the ICM Portal.</p>
        </div>
      </div>
    </Modal>
  );
};

export { ModalSessionExpired };
