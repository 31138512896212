import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MeetingAbsentDownloadAction";
import apiDownloadMeetingUpload from "./MeetingAbsentDownloadApis";
import * as Types from "./MeetingAbsentDownloadTypes";

// Replace with your sagas
export function* downloadMeetingAbsent(action) {
  try {
    const response = yield call(apiDownloadMeetingUpload, action.payload);
    yield put(Actions.actionReceiveDownloadMeetingAbsentSuccess(response));
  } catch (err) {
    yield put(Actions.actionReceiveDownloadMeetingAbsentError(err));
  }
}

export function* DownloadMeetingAbsent() {
  yield takeLatest(Types.REQUEST_DOWNLOAD_MEETING_ABSENT, downloadMeetingAbsent);
}
