import * as Types from "./CoachingDownloadDocFormTypes";

//Replace action name and update action types
export const actionRequestCoachingDownloadDocFormInit = () => {
  return {
    type: Types.REQUEST_DOWNLOAD_COACHING_DOC_FORM_INIT
  };
};

export const actionRequestCoachingDownloadDocForm = (id) => {
  return {
    type: Types.REQUEST_DOWNLOAD_COACHING_DOC_FORM,
    id
  };
};

export const actionReceiveCoachingDownloadDocFormSuccess = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_COACHING_DOC_FORM_SUCCESS,
    data
  };
};

export const actionReceiveCoachingDownloadDocFormError = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_COACHING_DOC_FORM_ERROR,
    data
  };
};
