import * as Types from "./CoachingUploadFormTypes";

//Replace action name and update action types
export const actionRequestCoachingUploadFormInit = () => {
  return {
    type: Types.REQUEST_COACHING_UPLOAD_FORM_INIT
  };
};

export const actionRequestCoachingUploadForm = (payload) => {
  const { file, id } = payload;
  return {
    type: Types.REQUEST_COACHING_UPLOAD_FORM,
    payload: {
      file,
      id
    }
  };
};

export const actionReceiveCoachingUploadFormSuccess = (data) => {
  return {
    type: Types.RECEIVE_COACHING_UPLOAD_FORM_SUCCESS,
    data
  };
};

export const actionReceiveCoachingUploadFormError = (data) => {
  return {
    type: Types.RECEIVE_COACHING_UPLOAD_FORM_ERROR,
    data
  };
};
