import * as types from "./AgentCreateTypes";

//Replace action name and update action types
export const actionRequestAdminAgentCreateInit = () => {
  return {
    type: types.ADMIN_AGENT_CREATE_INIT
  };
};

export const actionRequestAdminAgentCreate = (payload) => {
  const {
    code,
    agencyCode,
    status,
    appointmentDate,
    contractDate,
    modifiedDate,
    modifiedBy,
    grade,
    startDate,
    endDate,
    authorization
  } = payload;

  return {
    type: types.ADMIN_AGENT_CREATE_REQUEST,
    payload: {
      authorization,
      code,
      agencyCode,
      status,
      appointmentDate,
      contractDate,
      modifiedDate,
      modifiedBy,
      grade,
      startDate,
      endDate
    }
  };
};

export const actionReceiveAdminAgentCreateSuccess = (data) => {
  return {
    type: types.ADMIN_AGENT_CREATE_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveAdminAgentCreateError = (data) => {
  return {
    type: types.ADMIN_AGENT_CREATE_RECEIVE_ERROR,
    data
  };
};
