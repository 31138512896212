import * as types from "./AuthTypes";

//Replace action name and update action types
export const actionRequestAuthTokenInit = () => {
  return {
    type: types.REQUEST_AUTH_TOKEN_INIT
  };
};

export const actionRequestAuthToken = (payload) => {
  const { loginId, source } = payload;
  return {
    type: types.REQUEST_AUTH_TOKEN,
    payload: {
      loginId,
      source
    }
  };
};

export const actionReceiveAuthTokenSuccess = (data) => {
  return {
    type: types.RECEIVE_AUTH_TOKEN_SUCCESS,
    data
  };
};

export const actionReceiveAuthTokenError = (data) => {
  return {
    type: types.RECEIVE_AUTH_TOKEN_ERROR,
    data
  };
};
