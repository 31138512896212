export const theme = {
  colors: {
    w: "#FFFFFF",
    g1: "#FAFAFA",
    k: "#000000",
    p1: "#D31145",
    p2: "#596C80",
    p3: "#554344",
    p4: "#363E3F",
    p5: "#EEf0f1",
    s1: "#E86487",
    s2: "#FBBF56",
    s3: "#97CB5D",
    s4: "#46D3BD",
    s5: "#22A8DA",
    s6: "#9962D2",
    t1: "#F6CCD7",
    t2: "#B8123E",
    t3: "#F9E1B8",
    t4: "#C36418",
    t5: "#C6EB9E",
    t6: "#65962E",
    b1: "#D4EDF1",
    b2: "#FAF9F3",
    b3: "#F0EEE1",
    b4: "#DBDAD4",
    b5: "#B3B1A8",
    b6: "#6F6E68",
    b8: "#302F2C",
    b9: "#292025",
    b10: "#BFBFBF",
    u2: "#FF5500",
    u3: "#405B70",
    u4: "#769FAB"
  }
};

export const BASE_URL_API = `${process.env.REACT_APP_BASE_URL}/api`;
//export const BASE_URL_API = `${process.env.REACT_APP_BASE_URL}`;

export const formatTime = "HH:mm";
export const formatDate = "YYYY-MM-DD";
export const formatDateTime = "YYYY-MM-DD HH:mm:ss";
export const formatDateMonthAndYear = "YYYY-MM";

export const MonthConstants = [
  { id: 0, text: "All", value: "ALL" },
  { id: 1, text: "January", value: "01" },
  { id: 2, text: "February", value: "02" },
  { id: 3, text: "March", value: "03" },
  { id: 4, text: "April", value: "04" },
  { id: 5, text: "May", value: "05" },
  { id: 6, text: "June", value: "06" },
  { id: 7, text: "July", value: "07" },
  { id: 8, text: "August", value: "08" },
  { id: 9, text: "September", value: "09" },
  { id: 10, text: "October", value: "10" },
  { id: 11, text: "November", value: "11" },
  { id: 12, text: "December", value: "12" }
];

// @status:A->All(0,1,Y,N,C)
// @status:0->Pending Records;
// @status:1->Lapsed Records;@status:C->Cancelled Records;
// @status:Y->Accept Records;@status:N->Reject Records;

export const AssignmentStatusConstants = [
  { id: 0, text: "All", value: "ALL" },
  { id: 1, text: "Pending", value: "0" },
  { id: 2, text: "Accepted", value: "Y" },
  { id: 3, text: "Rejected", value: "N" },
  { id: 4, text: "Lapsed", value: "1" },
  { id: 5, text: "Cancelled", value: "C" }
];

export const AssignmentTypeConstants = [
  { id: 0, text: "All", value: "ALL" },
  { id: 1, text: "AMs FHR Endorsement", value: "AMs FHR" },
  { id: 2, text: "FSC FHR Endorsement", value: "OWN FHR" }
  // { id: 3, text: "T & C Duties", value: "CMJ" }
];

export const AssignmentTableConstants = [
  { id: 1, text: "Assignment to other Leader", value: "assignment-from" },
  { id: 2, text: "Assignment from other Leader", value: "assignment-to" }
];

export const CONST = {
  TIER_3: "Tier-3 Agent",
  TIER_2: "Tier-2 Agent",
  TIER_1: "Tier-1 Agent",
  PRESENT: "present",
  ABSENT: "absent",
  CHAIR: "chair",
  ASSIGNMENT_ERROR_MESSAGE_BOTH_FSC_AND_AM_FHR:
    "FSC and AM FHR Assignment record already exist. Please check your submitted records under Overview > Assignment History.",
  ASSIGNMENT_ERROR_MESSAGE_AM_FHR:
    "AM FHR Assignment record already exist. Please check your submitted records under Overview > Assignment History.",
  ASSIGNMENT_ERROR_MESSAGE_FSC_FHR:
    "FSC FHR Assignment record already exist. Please check your submitted records under Overview > Assignment History."
};
