import { createSelector } from "reselect";

/**
 * Creating selector for redux selector to use the state as the global state
 * @function all of the redux state selector
 * @return export selector state
 */

// 1. AUTH
const auth = (state) => state.auth;
const menu = (state) => state.menu;
const validate = (state) => state.validate;
export const makeGetAuth = createSelector(auth, (state) => state);
export const makeGetMenu = createSelector(menu, (state) => state);
export const makeValidate = createSelector(validate, (state) => state);

// 2. COMMON
const agencyData = (state) => state.agency.data;
const agentInfo = (state) => state.agentInfo;
const adminInfo = (state) => state.adminInfo;
const adminInfoValidate = (state) => state.adminInfoValidate;
const validateTiming = (state) => state.validateTiming;
const validatePolicy = (state) => state.validatePolicy;
const policyDetail = (state) => state.policyDetail;
const leaderList = (state) => state.leaderList;
const assignmentList = (state) => state.assignmentList;
const recordAcknowledgementList = (state) => state.recordAcknowledgementList;
const leaderAssignment = (state) => state.leaderAssignment;
const submitAssignment = (state) => state.submitAssignment;
const submitRecordAcknowledgement = (state) => state.submitRecordAcknowledgement;
const getNotification = (state) => state.getNotification;
const getAssignmentFrom = (state) => state.getAssignmentFrom;
const getAssignmentTo = (state) => state.getAssignmentTo;
const archiveAttachment = (state) => state.archiveAttachment;
export const makeAgencyData = createSelector(agencyData, (state) => state);
export const makeAgentInfo = createSelector(agentInfo, (state) => state);
export const makeAdminInfo = createSelector(adminInfo, (state) => state);
export const makeAdminInfoValidate = createSelector(adminInfoValidate, (state) => state);
export const makeValidateTiming = createSelector(validateTiming, (state) => state);
export const makeValidatePolicy = createSelector(validatePolicy, (state) => state);
export const makePolicyDetail = createSelector(policyDetail, (state) => state);
export const makeLeaderList = createSelector(leaderList, (state) => state);
export const makeLeaderAssignment = createSelector(leaderAssignment, (state) => state);
export const makeAssignmentList = createSelector(assignmentList, (state) => state);
export const makeRecordAcknowledgementList = createSelector(recordAcknowledgementList, (state) => state);
export const makeSubmitAssignment = createSelector(submitAssignment, (state) => state);
export const makeSubmitRecordAcknowledgement = createSelector(submitRecordAcknowledgement, (state) => state);
export const makeGetNotification = createSelector(getNotification, (state) => state);
export const makeGetAssignmentFrom = createSelector(getAssignmentFrom, (state) => state);
export const makeGetAssignmentTo = createSelector(getAssignmentTo, (state) => state);
export const makeArchiveAttachment = createSelector(archiveAttachment, (state) => state);

// 3. FSC - COACHING
const createCoaching = (state) => state.createCoaching;
const uploadCoaching = (state) => state.uploadCoaching;
const uploadCoachingForm = (state) => state.uploadCoachingForm;
const updateCoaching = (state) => state.updateCoaching;
const coachingSubmitted = (state) => state.coachingSubmitted;
const manageCoaching = (state) => state.manageCoaching;
const coachingGetById = (state) => state.coachingGetById;
const meetingGetParticipantLists = (state) => state.meetingGetParticipantLists;
const deleteCoaching = (state) => state.deleteCoaching;
export const makeCreateCoaching = createSelector(createCoaching, (state) => state);
export const makeUploadCoaching = createSelector(uploadCoaching, (state) => state);
export const makeUploadCoachingForm = createSelector(uploadCoachingForm, (state) => state);
export const makeUpdateCoaching = createSelector(updateCoaching, (state) => state);
export const makeDeleteCoaching = createSelector(deleteCoaching, (state) => state);
export const makeManageCoaching = createSelector(manageCoaching, (state) => state);
export const makeCoachingSubmitted = createSelector(coachingSubmitted, (state) => state);
export const makeCoachingGetById = createSelector(coachingGetById, (state) => state);
export const makeMeetingGetParticipantLists = createSelector(
  meetingGetParticipantLists,
  (state) => state
);

// 4. FSC - JOINT FIELD VISIT
const createJFV = (state) => state.createJFV;
const manageJFV = (state) => state.manageJFV;
const jfvSubmitted = (state) => state.jfvSubmitted;
const updateJFV = (state) => state.updateJFV;
const jfvGetById = (state) => state.jfvGetById;
const deleteJFV = (state) => state.deleteJFV;
export const makeCreateJFV = createSelector(createJFV, (state) => state);
export const makeUpdateJFV = createSelector(updateJFV, (state) => state);
export const makeDeleteJFV = createSelector(deleteJFV, (state) => state);
export const makeManageJFV = createSelector(manageJFV, (state) => state);
export const makeJFVSubmitted = createSelector(jfvSubmitted, (state) => state);
export const makeJFVGetById = createSelector(jfvGetById, (state) => state);

// 5. FSC - Meeting
const createMeeting = (state) => state.createMeeting;
const manageMeeting = (state) => state.manageMeeting;
const meetingSubmitted = (state) => state.meetingSubmitted;
const updateMeeting = (state) => state.updateMeeting;
const uploadMeeting = (state) => state.uploadMeeting;
const uploadMeetingForm = (state) => state.uploadMeetingForm;
const uploadAbsentMeeting = (state) => state.uploadAbsentMeeting;
const meetingGetById = (state) => state.meetingGetById;
const meetingGetAbsentUpload = (state) => state.meetingGetAbsentUpload;
const participant = (state) => state.participant;
const downloadMeetingAbsent = (state) => state.downloadMeetingAbsent;
const deleteMeeting = (state) => state.deleteMeeting;
export const makeCreateMeeting = createSelector(createMeeting, (state) => state);
export const makeManageMeeting = createSelector(manageMeeting, (state) => state);
export const makeMeetingSubmitted = createSelector(meetingSubmitted, (state) => state);
export const makeUpdateMeeting = createSelector(updateMeeting, (state) => state);
export const makeDeleteMeeting = createSelector(deleteMeeting, (state) => state);
export const makeUploadMeeting = createSelector(uploadMeeting, (state) => state);
export const makeUploadMeetingForm = createSelector(uploadMeetingForm, (state) => state);
export const makeUploadAbsentMeeting = createSelector(uploadAbsentMeeting, (state) => state);
export const makeMeetingGetById = createSelector(meetingGetById, (state) => state);
export const makeMeetingGetAbsentUpload = createSelector(meetingGetAbsentUpload, (state) => state);
export const makeDownloadMeetingAbsent = createSelector(downloadMeetingAbsent, (state) => state);
export const makeParticipant = createSelector(participant, (state) => state);

// 5. FSC - LEADER COACHING OVERVIEW
const adminAgency = (state) => state.adminAgency;
const agentDetails = (state) => state.agentDetails;
export const makeAdminAgency = createSelector(adminAgency, (state) => state);
export const makeAgentDetails = createSelector(agentDetails, (state) => state);

// 6. FSC - ARCHIVE
const archiveCoaching = (state) => state.archiveCoaching;
const archiveCoachingGetById = (state) => state.archiveCoachingGetById;
const archiveJFV = (state) => state.archiveJFV;
const archiveJfvGetById = (state) => state.archiveJfvGetById;
const archiveMeeting = (state) => state.archiveMeeting;
const archiveMeetingGetById = (state) => state.archiveMeetingGetById;
export const makeArchiveCoaching = createSelector(archiveCoaching, (state) => state);
export const makeArchiveCoachingGetById = createSelector(archiveCoachingGetById, (state) => state);
export const makeArchiveJFV = createSelector(archiveJFV, (state) => state);
export const makeArchiveJfvGetById = createSelector(archiveJfvGetById, (state) => state);
export const makeArchiveMeeting = createSelector(archiveMeeting, (state) => state);
export const makeArchiveMeetingGetById = createSelector(archiveMeetingGetById, (state) => state);

// 7. FSC - OWN JFV
const uploadOwnJFV = (state) => state.uploadOwnJFV;
export const makeUploadOwnJFV = createSelector(uploadOwnJFV, (state) => state);

const leaderJFVOverview = (state) => state.leaderJFVOverview;
export const makeLeaderJFVOverview = createSelector(leaderJFVOverview, (state) => state);
