import { createSlice } from "@reduxjs/toolkit";
import { CONST } from "@utils/Constants";

const INITIAL_CHECKED_ALL = { present: false, absent: false, chair: false };

const initialState = {
  attendances: [],
  dataAgentLists: [],
  dataAgentTier3Lists: [],
  dataAgentTier2Lists: [],
  checkedAll: INITIAL_CHECKED_ALL,
  checkedAlls: {},
  statusChecker: []
};

const participantSlice = createSlice({
  name: "participant",
  initialState,
  reducers: {
    setInitialParticipant() {
      return initialState;
    },
    resetAlls(state, _) {
      const { attendances, dataAgentTier2Lists, dataAgentTier3Lists } = state;
      if (attendances.length > 0) {
        attendances.forEach((data) => {
          data.status = "";
        });
      }
      if (dataAgentTier2Lists.length > 0) {
        dataAgentTier2Lists.forEach((_, i) => {
          dataAgentTier2Lists[i].dataAgents.forEach((data) => {
            data.status = "";
          });
        });
      }
      if (dataAgentTier3Lists.length > 0) {
        dataAgentTier3Lists.forEach((_, i) => {
          dataAgentTier3Lists[i].dataAgents.forEach((data) => {
            data.status = "";
          });
        });
      }
      state.checkedAlls = {};
      state.checkedAll = INITIAL_CHECKED_ALL;
      return state;
    },
    setAttandances(state, action) {
      const { attendances, id } = action.payload;
      state.attendances = attendances;

      if (id) {
        const mapChecker = new Map();
        const updatedAgent = state.attendances.find((data) => data.id === id);

        state.attendances.forEach((agent) => {
          if (agent.id === id) {
            state.attendances[id - 1] = {
              ...updatedAgent
            };
          }
        });

        const presents = state.attendances.filter((obj) => obj.status === CONST.PRESENT);
        const { agencyLeader } = state.attendances[0];
        if (presents.length === state.attendances.length) {
          mapChecker.set(agencyLeader, {
            ...mapChecker.get(agencyLeader),
            agencyLeader,
            allPresent: true
          });
        } else {
          mapChecker.set(agencyLeader, {
            ...mapChecker.get(agencyLeader),
            agencyLeader,
            allPresent: false
          });
        }

        const nonAgentTier1 = state.attendances.filter((obj) => obj.agentTier !== CONST.TIER_1);
        const chairs = state.attendances.filter(
          (obj) => obj.agentTier !== CONST.TIER_1 && obj.status === CONST.CHAIR
        );

        if (chairs.length === nonAgentTier1.length) {
          mapChecker.set(agencyLeader, {
            ...mapChecker.get(agencyLeader),
            agencyLeader,
            allChair: true
          });
        } else {
          mapChecker.set(agencyLeader, {
            ...mapChecker.get(agencyLeader),
            agencyLeader,
            allChair: false
          });
        }

        mapChecker.forEach((val) => {
          if (val.allPresent || val.allChair) {
            if (val.allPresent) {
              state.checkedAll = {
                absent: false,
                chair: false,
                present: true
              };
            }
            if (val.allChair) {
              state.checkedAll = {
                absent: false,
                chair: true,
                present: false
              };
            }
          } else {
            state.checkedAll = INITIAL_CHECKED_ALL;
          }
        });
      }

      return state;
    },
    setAttandanceDistricts(state, action) {
      const { attendances, id } = action.payload;
      state.attendances = attendances;

      attendances.forEach((val) => {
        state.dataAgentTier2Lists.forEach((agt2) => {
          agt2.dataAgents.forEach((props) => {
            if (val.agentCode === props.agentCode && val.status === "absent") {
              props.remarks = val.remarks;
            }
          });
        });
        state.dataAgentTier3Lists.forEach((agt3) => {
          agt3.dataAgents.forEach((props) => {
            if (val.agentCode === props.agentCode && val.status === "absent") {
              props.remarks = val.remarks;
            }
          });
        });
      });

      if (id) {
        const mapChecker = new Map();
        const updatedAgent = state.attendances.find((data) => data.id === id);

        state.dataAgentTier2Lists.forEach((val) => {
          val.dataAgents.forEach((agent, i) => {
            if (agent.id === id)
              val.dataAgents[i] = {
                ...updatedAgent
              };
          });
          const presents = val.dataAgents.filter((obj) => obj.status === CONST.PRESENT);
          if (presents.length === val.dataAgents.length) {
            mapChecker.set(val.agencyLeader, {
              ...mapChecker.get(val.agencyLeader),
              agencyLeader: val.agencyLeader,
              allPresent: true
            });
          } else {
            mapChecker.set(val.agencyLeader, {
              ...mapChecker.get(val.agencyLeader),
              agencyLeader: val.agencyLeader,
              allPresent: false
            });
          }

          const nonAgentTier1 = val.dataAgents.filter((obj) => obj.agentTier !== CONST.TIER_1);
          const chairs = val.dataAgents.filter(
            (obj) => obj.agentTier !== CONST.TIER_1 && obj.status === CONST.CHAIR
          );
          if (chairs.length === nonAgentTier1.length) {
            mapChecker.set(val.agencyLeader, {
              ...mapChecker.get(val.agencyLeader),
              agencyLeader: val.agencyLeader,
              allChair: true
            });
          } else {
            mapChecker.set(val.agencyLeader, {
              ...mapChecker.get(val.agencyLeader),
              agencyLeader: val.agencyLeader,
              allChair: false
            });
          }
        });

        state.dataAgentTier3Lists.forEach((val) => {
          val.dataAgents.forEach((agent, i) => {
            if (agent.id === id)
              val.dataAgents[i] = {
                ...updatedAgent
              };
          });
          const presents = val.dataAgents.filter((obj) => obj.status === CONST.PRESENT);
          if (presents.length === val.dataAgents.length) {
            mapChecker.set(val.agencyLeader, {
              ...mapChecker.get(val.agencyLeader),
              agencyLeader: val.agencyLeader,
              allPresent: true
            });
          } else {
            mapChecker.set(val.agencyLeader, {
              ...mapChecker.get(val.agencyLeader),
              agencyLeader: val.agencyLeader,
              allPresent: false
            });
          }
          const nonAgentTier1 = val.dataAgents.filter((obj) => obj.agentTier !== CONST.TIER_1);
          const chairs = val.dataAgents.filter(
            (obj) => obj.agentTier !== CONST.TIER_1 && obj.status === CONST.CHAIR
          );
          if (chairs.length === nonAgentTier1.length) {
            mapChecker.set(val.agencyLeader, {
              ...mapChecker.get(val.agencyLeader),
              agencyLeader: val.agencyLeader,
              allChair: true
            });
          } else {
            mapChecker.set(val.agencyLeader, {
              ...mapChecker.get(val.agencyLeader),
              agencyLeader: val.agencyLeader,
              allChair: false
            });
          }
        });

        mapChecker.forEach((val) => {
          if (val.allPresent || val.allChair) {
            if (val.allPresent) {
              state.checkedAlls[`radioOf${val.agencyLeader}`] = {
                absent: false,
                chair: false,
                present: true
              };
            }
            if (val.allChair) {
              state.checkedAlls[`radioOf${val.agencyLeader}`] = {
                absent: false,
                chair: true,
                present: false
              };
            }
          } else {
            state.checkedAlls[`radioOf${val.agencyLeader}`] = INITIAL_CHECKED_ALL;
          }
        });
      }

      return state;
    },
    setDataAgentLists(state, action) {
      const sorted = action.payload.sort(function (a, b) {
        return a.id - b.id;
      });
      state.dataAgentLists = sorted;
      state.attendances = sorted;

      return state;
    },
    setDataAgentTier3Lists(state, action) {
      state.dataAgentTier3Lists = action.payload;
      return state;
    },
    setDataAgentTier2Lists(state, action) {
      state.dataAgentTier2Lists = action.payload;
      return state;
    },

    // for handling ModalSelecParticipant
    onCheckAll(state, action) {
      const { attendances } = state;
      const { e, status, form } = action.payload;
      if (e.target.checked && status === CONST.PRESENT) {
        attendances.forEach((data) => {
          form.setFieldsValue({ [data.id]: CONST.PRESENT });
          data.status = CONST.PRESENT;
        });
        const dataStatus = attendances.filter((data) => data.status === CONST.PRESENT);
        if (dataStatus) {
          if (attendances.length === dataStatus.length)
            state.checkedAll = { present: true, absent: false, chair: false };
        }
      } else if (e.target.checked && status === CONST.ABSENT) {
        attendances.forEach((data) => {
          form.setFieldsValue({ [data.id]: CONST.ABSENT });
          data.status = CONST.ABSENT;
        });
        const dataStatus = attendances.filter((data) => data.status === CONST.ABSENT);
        if (dataStatus) {
          if (attendances.length === dataStatus.length)
            state.checkedAll = { present: false, absent: true, chair: false };
        }
      } else if (e.target.checked && status === CONST.CHAIR) {
        let dataTier1 = attendances.filter((data) => data.agentTier !== CONST.TIER_1);
        dataTier1.forEach((data) => {
          form.setFieldsValue({ [data.id]: CONST.CHAIR });
          attendances[0] = {
            ...data,
            status: CONST.CHAIR
          };
        });
        const dataStatus = attendances.filter((data) => data.status === CONST.CHAIR);
        if (dataStatus) {
          if (dataStatus.length > 0)
            state.checkedAll = { present: false, absent: false, chair: true };
        }
      } else {
        state.checkedAll = { present: false, absent: false, chair: false };
        attendances.forEach((data) => {
          form.setFieldsValue({ [data.id]: "" });
          data.status = "";
        });
      }
      return state;
    },
    // for handling ModalSelecParticipantCombinedDistrict
    onCheckAlls(state, action) {
      const { checkedAlls, dataAgentTier2Lists, dataAgentTier3Lists } = state;
      const { e, tier, status, dataAgents, form } = action.payload;
      const { checked } = e.target;
      const [radioStatus, radioKey] = status.split(" ");
      if (checked) {
        if (radioStatus === CONST.PRESENT) {
          checkedAlls[radioKey] = {
            absent: false,
            chair: false,
            present: true
          };
          dataAgents.forEach((data) => {
            form.setFieldsValue({ [data.id]: CONST.PRESENT });
            state.attendances.forEach((val) => {
              if (val.id === data.id) {
                val.status = CONST.PRESENT;
              }
            });
            dataAgentTier2Lists.forEach((_, i) => {
              dataAgentTier2Lists[i].dataAgents.forEach((val) => {
                if (val.id === data.id) val.status = CONST.PRESENT;
              });
            });
            dataAgentTier3Lists.forEach((_, i) => {
              dataAgentTier3Lists[i].dataAgents.forEach((val) => {
                if (val.id === data.id) val.status = CONST.PRESENT;
              });
            });
          });
        }
        if (radioStatus === CONST.ABSENT) {
          checkedAlls[radioKey] = {
            ...checkedAlls[radioKey],
            absent: true,
            chair: false,
            present: false
          };
          dataAgents.forEach((agent) => {
            form.setFieldsValue({ [agent.id]: CONST.ABSENT });
            state.attendances.forEach((val) => {
              if (val.id === agent.id) val.status = CONST.ABSENT;
            });
            dataAgentTier2Lists.forEach((_, i) => {
              dataAgentTier2Lists[i].dataAgents.forEach((val) => {
                if (val.id === agent.id) val.status = CONST.ABSENT;
              });
            });
            dataAgentTier3Lists.forEach((_, i) => {
              dataAgentTier3Lists[i].dataAgents.forEach((val) => {
                if (val.id === agent.id) val.status = CONST.ABSENT;
              });
            });
          });
        }
        if (radioStatus === CONST.CHAIR) {
          checkedAlls[radioKey] = {
            absent: false,
            chair: true,
            present: false
          };
          if (tier === CONST.TIER_3) {
            dataAgents.forEach((agent) => {
              state.attendances.forEach((val) => {
                if (val.id === agent.id && val.agentTier !== CONST.TIER_1) {
                  val.status = CONST.CHAIR;
                  form.setFieldsValue({ [agent.id]: CONST.CHAIR });
                }
              });
              dataAgentTier2Lists.forEach((_, i) => {
                dataAgentTier2Lists[i].dataAgents.forEach((val) => {
                  if (val.id === agent.id && val.agentTier !== CONST.TIER_1)
                    val.status = CONST.CHAIR;
                });
              });
              dataAgentTier3Lists.forEach((_, i) => {
                dataAgentTier3Lists[i].dataAgents.forEach((val) => {
                  if (val.id === agent.id && val.agentTier !== CONST.TIER_1)
                    val.status = CONST.CHAIR;
                });
              });
            });
          }
          if (tier === CONST.TIER_2) {
            dataAgents.forEach((agent) => {
              state.attendances.forEach((val) => {
                if (val.id === agent.id && val.agentTier !== CONST.TIER_1) {
                  val.status = CONST.CHAIR;
                  form.setFieldsValue({ [agent.id]: CONST.CHAIR });
                }
              });
              dataAgentTier2Lists.forEach((_, i) => {
                dataAgentTier2Lists[i].dataAgents.forEach((val) => {
                  if (val.id === agent.id && val.agentTier !== CONST.TIER_1)
                    val.status = CONST.CHAIR;
                });
              });
              dataAgentTier3Lists.forEach((_, i) => {
                dataAgentTier3Lists[i].dataAgents.forEach((val) => {
                  if (val.id === agent.id && val.agentTier !== CONST.TIER_1)
                    val.status = CONST.CHAIR;
                });
              });
            });
          }
        }
      } else {
        state.checkedAlls[radioKey] = INITIAL_CHECKED_ALL;
        if (radioStatus === CONST.CHAIR) {
          if (tier === CONST.TIER_3) {
            dataAgents.forEach((agent) => {
              state.attendances.forEach((val) => {
                if (val.id === agent.id && val.agentTier !== CONST.TIER_1) {
                  val.status = CONST.CHAIR;
                  form.setFieldsValue({ [agent.id]: "" });
                }
              });
              dataAgentTier2Lists.forEach((_, i) => {
                dataAgentTier2Lists[i].dataAgents.forEach((val) => {
                  if (val.id === agent.id && val.agentTier !== CONST.TIER_1)
                    val.status = CONST.CHAIR;
                });
              });
              dataAgentTier3Lists.forEach((_, i) => {
                dataAgentTier3Lists[i].dataAgents.forEach((val) => {
                  if (val.id === agent.id && val.agentTier !== CONST.TIER_1)
                    val.status = CONST.CHAIR;
                });
              });
            });
          }
          if (tier === CONST.TIER_2) {
            dataAgents.forEach((agent) => {
              state.attendances.forEach((val) => {
                if (val.id === agent.id && val.agentTier !== CONST.TIER_1) {
                  val.status = CONST.CHAIR;
                  form.setFieldsValue({ [agent.id]: "" });
                }
              });
              dataAgentTier2Lists.forEach((_, i) => {
                dataAgentTier2Lists[i].dataAgents.forEach((val) => {
                  if (val.id === agent.id && val.agentTier !== CONST.TIER_1)
                    val.status = CONST.CHAIR;
                });
              });
              dataAgentTier3Lists.forEach((_, i) => {
                dataAgentTier3Lists[i].dataAgents.forEach((val) => {
                  if (val.id === agent.id && val.agentTier !== CONST.TIER_1)
                    val.status = CONST.CHAIR;
                });
              });
            });
          }
        } else {
          dataAgents.forEach((agent) => {
            form.setFieldsValue({ [agent.id]: "" });
            state.attendances.forEach((val) => {
              if (val.id === agent.id) {
                val.status = "";
              }
            });
            dataAgentTier2Lists.forEach((_, i) => {
              dataAgentTier2Lists[i].dataAgents.forEach((val) => {
                if (val.id === agent.id) val.status = "";
              });
            });
            dataAgentTier3Lists.forEach((_, i) => {
              dataAgentTier3Lists[i].dataAgents.forEach((val) => {
                if (val.id === agent.id) val.status = "";
              });
            });
          });
        }
      }
    }
  }
});

const {
  setInitialParticipant,
  setAttandances,
  setAttandanceDistricts,
  setDataAgentLists,
  setDataAgentTier3Lists,
  setDataAgentTier2Lists,
  onCheckAll,
  onCheckAlls,
  resetAlls
} = participantSlice.actions;

export {
  setInitialParticipant,
  setAttandances,
  setAttandanceDistricts,
  setDataAgentLists,
  setDataAgentTier3Lists,
  setDataAgentTier2Lists,
  onCheckAll,
  onCheckAlls,
  resetAlls
};
export default participantSlice.reducer;
