import * as types from "./RecordAcknowledgementListTypes";

//Replace action name and update action types
export const actionRequestRecordAcknowledgementList = (agencyCode) => {
    return {
        type: types.REQUEST_RECORDACKNOWLEDGEMENT_LIST,
        agencyCode
    };
};

export const actionReceiveRecordAcknowledgementListSuccess = (data) => {
    return {
        type: types.RECEIVE_RECORDACKNOWLEDGEMENT_LIST_SUCCESS,
        data
    };
};

export const actionReceiveRecordAcknowledgementListError = (data) => {
    return {
        type: types.RECEIVE_RECORDACKNOWLEDGEMENT_LIST_ERROR,
        data
    };
};
