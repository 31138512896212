import * as types from "./AgentSearchTypes";

//Replace action name and update action types
export const actionRequestAdminAgentSearchInit = () => {
  return {
    type: types.ADMIN_AGENT_SEARCH_INIT
  };
};

export const actionRequestAdminAgentSearch = (payload) => {
  const { code, agencyCode, authorization } = payload;
  return {
    type: types.ADMIN_AGENT_SEARCH_REQUEST,
    payload: {
      authorization,
      code,
      agencyCode
    }
  };
};

export const actionReceiveAdminAgentSearchSuccess = (data) => {
  return {
    type: types.ADMIN_AGENT_SEARCH_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveAdminAgentSearchError = (data) => {
  return {
    type: types.ADMIN_AGENT_SEARCH_RECEIVE_ERROR,
    data
  };
};
