import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetCoachingById = async (id) => {
  const auth = Cookies.get("token");
  let params = {};
  let config = {};
  if (id) params["id"] = id;
  if (auth) {
    config = {
      params,
      headers: {
        "x-ior-auth": auth
      }
    };
  }
  return await axiosInstance.get(`/coaching/getCoachingById`, config);
};

export default apiGetCoachingById;
