import * as types from "./AdminInfoForValidateTypes";

//Replace action name and update action types
export const actionRequestAdminInfoValidateInit = () => {
  return {
    type: types.REQUEST_ADMIN_INFO_VALIDATE_INIT
  };
};

export const actionRequestAdminInfoValidate = (code) => {
  return {
    type: types.REQUEST_ADMIN_INFO_VALIDATE,
    code
  };
};

export const actionReceiveAdminInfoValidateSuccess = (data) => {
  return {
    type: types.RECEIVE_ADMIN_INFO_VALIDATE_SUCCESS,
    data
  };
};

export const actionReceiveAdminInfoValidateError = (data) => {
  return {
    type: types.RECEIVE_ADMIN_INFO_VALIDATE_ERROR,
    data
  };
};
