import * as Types from "./MeetingUploadTypes";

//Replace action name and update action types
export const actionMeetingUploadInit = () => {
  return {
    type: Types.MEETING_UPLOAD_INIT
  };
};

export const actionRequestMeetingUpload = (payload) => {
  const { file, id } = payload;
  return {
    type: Types.MEETING_UPLOAD_REQUEST,
    payload: {
      file,
      id
    }
  };
};

export const actionReceiveMeetingUploadSuccess = (data) => {
  return {
    type: Types.MEETING_UPLOAD_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveMeetingUploadError = (data) => {
  return {
    type: Types.MEETING_UPLOAD_RECEIVE_ERROR,
    data
  };
};
