import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ArchiveCoachingAction";
import apiGetArchiveCoaching from "./ArchiveCoachingApis";
import * as Types from "./ArchiveCoachingTypes";

// Replace with your sagas
export function* archiveCoaching(action) {
  try {
    const response = yield call(apiGetArchiveCoaching, action.payload);
    yield put(Actions.actionRecArchiveCoachingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRecArchiveCoachingError(err.response.data));
  }
}

export function* ArchiveCoaching() {
  yield takeLatest(Types.REQUEST_ARCHIVE_COACHING, archiveCoaching);
}
