import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiMeetingDownloadDocForm = async (id) => {
  const auth = Cookies.get("token");
  let config;
  let params = {};
  if (id) params["id"] = id;
  if (auth) {
    config = {
      responseType: "arraybuffer",
      params,
      headers: {
        "x-ior-auth": auth,
        "Content-Type": "application/json",
        Accept: "application/pdf"
      }
    };
  }
  let result;
  await axiosInstance
    .get(`meeting/downloadForm`, config)
    .then((response) => {
      let headerLine = response.headers["content-disposition"];
      let filename = headerLine.substring(20, headerLine.length);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      link.click();
      result = response;
    })
    .catch((error) => console.log(error));
  return result;
};

export default apiMeetingDownloadDocForm;
