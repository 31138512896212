import { createGlobalStyle } from "styled-components";
import "antd/dist/antd.less";

import { media } from "./constants/MediaConstants";

const GlobalStyle = createGlobalStyle`
  body h1 {
    font-family: "AIACondensedMedium", "Calibri", sans-serif;
    font-size: 96px;
    line-height: 84px;
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.colors.p3}
  }

  body h2 {
    font-family: "AIACondensedMedium", "Calibri", sans-serif;
    font-size: 72px;
    line-height: 64px;
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.colors.p3}
  }

  body h3 {
    font-family: "AIACondensedMedium", "Calibri", sans-serif;
    font-size: 48px;
    line-height: 44px;
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.colors.p3}
  }

  body h4 {
    font-family: "AIACondensedMedium", "Calibri", sans-serif;
    font-size: 32px;
    line-height: 28px;
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.colors.p3}
  }

  body h5 {
    font-family: "AIACondensedMedium", "Calibri", sans-serif;
    font-size: 24px;
    line-height: 22px;
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.colors.p3}
  }

  body h6 {
    font-family: "AIACondensedMedium", "Calibri", sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.colors.p3}
  }

  body p, ul, ol {
    font-family: "AIARegular", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.colors.p3}
  }
   
  .ant-layout {
    background-color: ${({ theme }) => theme.colors.g1};
  }

  
  .ant-layout-content {
    h1 {
      ${media.mobileS} {
        line-height: 35px !important;
      }

      ${media.tabletM} {
        line-height: 45px !important;
      }

      ${media.tabletL} {
        line-height: 55px !important;
      }

      ${media.laptopM} {
        line-height: 82px !important;
        font-size: 32pt;
      }
    }

    h6,
    .ant-form-item-label > label {
      font-size: clamp(12px, -1.1rem + 8.333333vw, 20px);
      font-family: "AIACondensedMedium", "Calibri", sans-serif;
      line-height: 20px;
      font-weight: normal;
      margin-top: 0px;
      margin-bottom: 0px;
      color: #554344;
    }
  } 
  
  .ant-layout-content {
    padding-top: clamp(9rem, 5.9rem + 8.33333vw, 14rem);
    padding-bottom: 8%;
    background-color: ${({ theme }) => theme.colors.w};
    box-shadow: #0000001c -1px -20px 20px 0px;
    z-index: 1;
  }
  
  .ant-message{
    font-size: 16px;
  }
  
  .ant-modal-header {
    background-color: #d31145;
    border-radius: unset;
  }
  
  .ant-modal-title {
    text-align: center;
    color: #fff;
    font-family: "AIAMedium",Arial,"Helvetica Neue",Helvetica,sans-serif;
    font-size: 16px;
  }
  
  .ant-modal-footer .ant-btn-primary {
    background-color: #d31145;
    border: none!important;
  }

  .ant-modal-footer .ant-btn {
    border-radius: unset!important;
  }
  
  .aside-active {
    display: block;
  }
  .aside-in-active {
    display: none;
  }
  
  .icon-aside-active{
    left: 20%;
  }
  
  .ant-tooltip-placement-right{
    position: fixed;
  }
  
  .button-upload {
    width: 100%;
    div,
    button {
      width: 100%;
    }
  }
  
  .ant-upload-text-icon {
    display: contents;
  }
  
  .box-message {
   display: flex;
   align-items: end;
  }
  
  .box-message svg {
    color: ${({ theme }) => theme.colors.p1};
    font-size: 32px;
    margin: 0 30px;
  } 
  
`;

export default GlobalStyle;
