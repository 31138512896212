import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ArchiveCoachingExportAction";
import apiGetArchiveCoachingExport from "./ArchiveCoachingExportApis";
import * as Types from "./ArchiveCoachingExportTypes";

// Replace with your sagas
export function* archiveCoachingExport(action) {
  try {
    const response = yield call(apiGetArchiveCoachingExport, action.payload);
    yield put(Actions.actionRecArchiveCoachingExportSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRecArchiveCoachingExportError(err.response.data));
  }
}

export function* ArchiveCoachingExport() {
  yield takeLatest(Types.REQUEST_ARCHIVE_COACHING_EXPORT, archiveCoachingExport);
}
