import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./CoachingDownloadDocAction";
import apiCoachingDownloadDoc from "./CoachingDownloadDocApis";
import * as Types from "./CoachingDownloadDocTypes";

// Replace with your sagas
export function* coachingDownloadDoc(action) {
  try {
    const response = yield call(apiCoachingDownloadDoc, action.id);
    yield put(Actions.actionReceiveCoachingDownloadDocSuccess(response));
  } catch (err) {
    yield put(Actions.actionReceiveCoachingDownloadDocError(err.response));
  }
}

export function* CoachingDownloadDoc() {
  yield takeLatest(Types.REQUEST_DOWNLOAD_COACHING_DOC, coachingDownloadDoc);
}
