import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ValidateTimingAction";
import apiGetValidateTiming from "./ValidateTimingApis";
import * as Types from "./ValidateTimingTypes";

// Replace with your sagas
export function* validateTiming(action) {
  try {
    const response = yield call(apiGetValidateTiming, action.payload);
    yield put(Actions.actionReceiveValidateTimingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveValidateTimingError(err.response.data.message));
  }
}

export function* ValidateTiming() {
  yield takeLatest(Types.VALIDATE_TIMING_REQUEST, validateTiming);
}
