import * as Types from "./CoachingDownloadDocTypes";

//Replace action name and update action types
export const actionRequestCoachingDownloadDocInit = () => {
  return {
    type: Types.REQUEST_DOWNLOAD_COACHING_DOC_INIT
  };
};

export const actionRequestCoachingDownloadDoc = (id) => {
  return {
    type: Types.REQUEST_DOWNLOAD_COACHING_DOC,
    id
  };
};

export const actionReceiveCoachingDownloadDocSuccess = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_COACHING_DOC_SUCCESS,
    data
  };
};

export const actionReceiveCoachingDownloadDocError = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_COACHING_DOC_ERROR,
    data
  };
};
