import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./GetAssignmentFromAction";
import apiGetAssignmentFrom from "./GetAssignmentFromApis";
import * as Types from "./GetAssignmentFromTypes";

// Replace with your sagas
export function* getAssignmentFrom(action) {
  try {
    const response = yield call(apiGetAssignmentFrom, action.payload);
    yield put(Actions.actionReceiveGetAssignmentFromSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveGetAssignmentFromError(err.response.data));
  }
}

export function* GetAssignmentFrom() {
  yield takeLatest(Types.REQUEST_GET_ASSIGNMENT_FROM, getAssignmentFrom);
}
