import * as types from "./JFVDeleteTypes";

//Replace action name and update action types
export const actionRequestJFVByIdInit = () => {
  return {
    type: types.DELETE_JFV_INIT
  };
};

export const actionRequestDeleteJFVInit = () => {
  return {
    type: types.DELETE_JFV_INIT
  };
};

export const actionRequestDeleteJFV = (payload) => {
    const {
      id
    } = payload;
    return {
      type: types.DELETE_JFV_REQUEST,
      payload: {
        id
      }
    };
  };
  
  export const actionReceiveDeleteJFVSuccess = (data) => {
    return {
      type: types.DELETE_JFV_RECEIVE_SUCCESS,
      data
    };
  };
  
  export const actionReceiveDeleteJFVError = (data) => {
    return {
      type: types.DELETE_JFV_RECEIVE_ERROR,
      data
    };
  };
  