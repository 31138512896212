import * as types from "./ArchiveJFVGetByIdTypes";

//Replace action name and update action types
export const actionRequestJfvArchiveByIdInit = () => {
  return {
    type: types.REQUEST_GET_JFV_ARCHIVE_BY_ID_INIT
  };
};

export const actionRequestJfvArchiveById = (id) => {
  return {
    type: types.REQUEST_GET_JFV_ARCHIVE_BY_ID,
    id
  };
};

export const actionRequestJfvArchiveByIdSuccess = (data) => {
  return {
    type: types.RECEIVE_GET_JFV_ARCHIVE_BY_ID_SUCCESS,
    data
  };
};

export const actionRequestJfvArchiveByIdError = (data) => {
  return {
    type: types.RECEIVE_GET_JFV_ARCHIVE_BY_ID_ERROR,
    data
  };
};
