import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./AgentSearchAction";
import apiGetAdminAgentSearch from "./AgentSearchApis";
import * as Types from "./AgentSearchTypes";

// Replace with your sagas
export function* getDataAdminAgentSearch(action) {
  try {
    const response = yield call(apiGetAdminAgentSearch, action.payload);
    yield put(Actions.actionReceiveAdminAgentSearchSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveAdminAgentSearchError(err.response.data));
  }
}

export function* AdminAgentSearch() {
  yield takeLatest(Types.ADMIN_AGENT_SEARCH_REQUEST, getDataAdminAgentSearch);
}
