import * as types from "./AdminInfoTypes";

const INITIAL_STATE = {
  success: false,
  data: []
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_ADMIN_INFO_INIT:
      return INITIAL_STATE;
    case types.RECEIVE_ADMIN_INFO_SUCCESS:
      return {
        ...action.data,
        success: true
      };
    case types.RECEIVE_ADMIN_INFO_ERROR:
      return {
        data: action.data,
        success: false
      };

    default:
      return state;
  }
};
