import * as types from "./AuthTypes";

const INITIAL_STATE = {
  loginData: "",
  success: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_AUTH_TOKEN_INIT:
      return INITIAL_STATE;
    case types.RECEIVE_AUTH_TOKEN_SUCCESS:
      const loginData = action.data.data;
      let oldDateObj = new Date();
      let newDateObj = new Date();
      newDateObj.setTime(oldDateObj.getTime() + 14 * 60 * 1000);
      return {
        loginData,
        exp: newDateObj.toISOString(),
        success: true
      };
    case types.RECEIVE_AUTH_TOKEN_ERROR:
      return {
        loginData: "",

        success: false
      };
    default:
      return state;
  }
};
