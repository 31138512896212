import * as types from "./AgencyTypes";

//Replace action name and update action types
export const actionRequestAgentInfoInit = () => {
  return {
    type: types.REQUEST_AGENT_INFO_INIT
  };
};

export const actionRequestAgentInfo = (agentCode) => {
  return {
    type: types.REQUEST_AGENT_INFO,
    agentCode
  };
};

export const actionReceiveAgentInfoSuccess = (data) => {
  return {
    type: types.RECEIVE_AGENT_INFO_SUCCESS,
    data
  };
};

export const actionReceiveAgentInfoError = (data) => {
  return {
    type: types.RECEIVE_AGENT_INFO_ERROR,
    data
  };
};
