import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ManageJFVAction";
import apiGetManageJFV from "./ManageJFVApis";
import * as Types from "./ManageJFVTypes";

// Replace with your sagas
export function* getDataManageJFV(action) {
  try {
    const response = yield call(apiGetManageJFV, action.payload);
    yield put(Actions.actionReceiveManageJFVSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveManageJFVError(err.response.data));
  }
}

export function* ManageJFV() {
  yield takeLatest(Types.MANAGE_JFV_REQUEST, getDataManageJFV);
}
