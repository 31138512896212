import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./AgentDetailsAction";
import apiGetAgentDetails from "./AgentDetailsApis";
import * as Types from "./AgentDetailsTypes";

// Replace with your sagas
export function* getDataAgentDetails(action) {
  try {
    const response = yield call(apiGetAgentDetails, action.payload);
    yield put(Actions.actionReceiveAgentDetailsSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveAgentDetailsError(err.response.data));
  }
}

export function* AgentDetails() {
  yield takeLatest(Types.ADMIN_DATA_AGENT_DETAILS_REQUEST, getDataAgentDetails);
}
