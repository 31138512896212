import * as types from "./ArchiveJFVExportTypes";

//Replace action name and update action types
export const actionReqArchiveJFVExportInit = () => {
  return {
    type: types.REQUEST_ARCHIVE_JFV_EXPORT_INIT
  };
};

export const actionReqArchiveJFVExport = (payload) => {
  const { startDate, endDate, code, status } = payload;
  return {
    type: types.REQUEST_ARCHIVE_JFV_EXPORT,
    payload: {
      code,
      startDate,
      endDate,
      status
    }
  };
};

export const actionRecArchiveJFVExportSuccess = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_JFV_EXPORT_SUCCESS,
    data
  };
};

export const actionRecArchiveJFVExportError = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_JFV_EXPORT_ERROR,
    data
  };
};
