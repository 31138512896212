import { axiosInstance } from "@network/apis";
import Auth from "@utils/Auth";

// Replace endpoint and change api name
const apiGetValidatePolicy = async (payload) => {
  const auth = Auth.isValidate();
  const { polNum, agent, agencyLeader } = payload;

  const result = await axiosInstance.post(
    `/common/validatePolicy`,
    {
      polNum,
      agent,
      agencyLeader
    },
    {
      headers: {
        "x-ior-auth": auth
      }
    }
  );
  return result;
};

export default apiGetValidatePolicy;
