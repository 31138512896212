import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiUpdateMeeting = async (payload) => {
  const {
    id,
    agencyCode, //
    agencyName,
    leaderCode,
    meetingDate,
    startTime,
    endTime,
    type,
    status,
    chair,
    present,
    absent,
    topics,
    deletedBy,
    deletedByName
  } = payload;
  const auth = Cookies.get("token");
  let config;
  if (auth) {
    config = {
      headers: {
        "x-ior-auth": auth
      }
    };
  }

  const result = await axiosInstance.post(
    `/meeting/update`,
    {
      id,
      agencyCode,
      agencyName,
      leaderCode,
      meetingDate,
      startTime,
      endTime,
      type,
      status,
      chair,
      present,
      absent,
      topics,
      deletedBy,
      deletedByName
    },
    config
  );
  return result;
};

export default apiUpdateMeeting;
