import Auth from "@utils/Auth";
import { axiosInstance } from "@network/apis";

// Replace endpoint and change api name
const apiGetAssignmentFrom = async (payload) => {
  const auth = Auth.isValidate();
  const { leaderCode, agencyCode, month, year, status, type } = payload;
  let data = {};
  data["status"] = status;
  if (leaderCode) data["leaderCode"] = leaderCode;
  if (agencyCode) data["agencyCode"] = agencyCode;
  if (month) data["month"] = month;
  if (year) data["year"] = year;
  if (type) data["type"] = type;

  const config = {
    headers: {
      "x-ior-auth": auth
    }
  };
  const result = await axiosInstance.post(`/common/getAssignmentFrom`, data, config);
  return result;
};

export default apiGetAssignmentFrom;
