import * as Types from "./MeetingAbsentDownloadTypes";

//Replace action name and update action types
export const actionRequestDownloadMeetingAbsentInit = () => {
  return {
    type: Types.REQUEST_DOWNLOAD_MEETING_ABSENT_INIT
  };
};

export const actionRequestDownloadMeetingAbsent = (payload) => {
  const { id, agentCode } = payload;
  return {
    type: Types.REQUEST_DOWNLOAD_MEETING_ABSENT,
    payload: {
      id,
      agentCode
    }
  };
};

export const actionReceiveDownloadMeetingAbsentSuccess = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_MEETING_ABSENT_SUCCESS,
    data
  };
};

export const actionReceiveDownloadMeetingAbsentError = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_MEETING_ABSENT_ERROR,
    data
  };
};
