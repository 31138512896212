import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MeetingGetParticipantListActions";
import apiGetMeetingParticipantLists from "./MeetingGetParticipantListApis";
import * as Types from "./MeetingGetParticipantListTypes";

// Replace with your sagas
export function* meetingGetParticipantLists(action) {
  try {
    const response = yield call(apiGetMeetingParticipantLists, action.payload);
    yield put(Actions.actionRequestGetMeetingParticipantListsSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRequestGetMeetingParticipantListsError(err));
  }
}

export function* MeetingGetParticipantLists() {
  yield takeLatest(Types.REQUEST_GET_MEETING_PARTICIPANT_LIST, meetingGetParticipantLists);
}
