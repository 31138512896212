import * as types from "./LeaderListTypes";

//Replace action name and update action types
export const actionRequestLeaderList = (agentCode) => {
  return {
    type: types.REQUEST_LEADER_LIST,
    agentCode
  };
};

export const actionReceiveLeaderListSuccess = (data) => {
  return {
    type: types.RECEIVE_LEADER_LIST_SUCCESS,
    data
  };
};

export const actionReceiveLeaderListError = (data) => {
  return {
    type: types.RECEIVE_LEADER_LIST_ERROR,
    data
  };
};
