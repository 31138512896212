import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./AgencyAction";
import apiGetAgentInfo from "./AgencyApis";
import * as Types from "./AgencyTypes";

// Replace with your sagas
export function* getAgentInfo(action) {
  try {
    const response = yield call(apiGetAgentInfo, action.agentCode);
    yield put(Actions.actionReceiveAgentInfoSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveAgentInfoError(err.response.data));
  }
}

export function* AgentInfo() {
  yield takeLatest(Types.REQUEST_AGENT_INFO, getAgentInfo);
}
