import * as types from "./ArchiveCoachingExportTypes";

//Replace action name and update action types
export const actionReqArchiveCoachingExportInit = () => {
  return {
    type: types.REQUEST_ARCHIVE_COACHING_EXPORT_INIT
  };
};

export const actionReqArchiveCoachingExport = (payload) => {
  const { code, status, startDate, endDate } = payload;
  return {
    type: types.REQUEST_ARCHIVE_COACHING_EXPORT,
    payload: {
      code,
      startDate,
      endDate,
      status
    }
  };
};

export const actionRecArchiveCoachingExportSuccess = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_COACHING_EXPORT_SUCCESS,
    data
  };
};

export const actionRecArchiveCoachingExportError = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_COACHING_EXPORT_ERROR,
    data
  };
};
