import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./LeaderListAction";
import apiGetLeaderList from "./LeaderListApis";
import * as Types from "./LeaderListTypes";

// Replace with your sagas
export function* getLeaderList(action) {
  try {
    const response = yield call(apiGetLeaderList, action.agentCode);
    yield put(Actions.actionReceiveLeaderListSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveLeaderListError(err.response.data));
  }
}

export function* LeaderList() {
  yield takeLatest(Types.REQUEST_LEADER_LIST, getLeaderList);
}
