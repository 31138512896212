import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiCreateJFV = async (payload) => {
  const auth = Cookies.get("token");
  let config = {};
  if (auth) {
    config = {
      headers: {
        "x-ior-auth": auth
      }
    };
  }

  const {
    agencyCode,
    leaderCode,
    agentCode,
    startDate,
    endDate,
    startTime,
    endTime,
    visitType,
    location,
    policyNumber,
    addpolicyNumber,
    customerName,
    score,
    comments,
    qna,
    status,
    agencyName,
    leaderName,
    agentName,
    source
  } = payload;

  return await axiosInstance.post(
    `/jfv/create`,
    {
      agencyCode,
      leaderCode,
      agentCode,
      startDate,
      endDate,
      startTime,
      endTime,
      visitType,
      location,
      policyNumber,
      addpolicyNumber,
      customerName,
      score,
      comments,
      qna,
      status,
      agencyName,
      leaderName,
      agentName,
      source
    },
    config
  );
};

export default apiCreateJFV;
