import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetRecordAcknowledgementList = async (agencyCode) => {
    const auth = Cookies.get("token");
    const agentCode = Cookies.get("login_id");
    let data = {};
    let headers = {};
    if (agentCode) data["agentCode"] = agentCode;
    if (agencyCode) data["agencyCode"] = agencyCode;
    if (auth)
        headers = {
            "x-ior-auth": auth
        };
    return await axiosInstance.post(`/common/getRecordAcknowledgementList`, data, {
        headers
    });
};

export default apiGetRecordAcknowledgementList;
