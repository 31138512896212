import Auth from "@utils/Auth";
import { axiosInstance } from "@network/apis";

// Replace endpoint and change api name
const apiSubmitRecordAcknowledgement = async (payload) => {
    const auth = Auth.isValidate();
    const config = {
        headers: {
            "x-ior-auth": auth
        }
    };
    const result = await axiosInstance.post(`/common/submitRecordAcknowledgement`, payload, config);
    return result;
};

export default apiSubmitRecordAcknowledgement;
