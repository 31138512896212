import * as types from "./AgentDetailsTypes";

//Replace action name and update action types
export const actionRequestAgentDetailsInit = () => {
  return {
    type: types.ADMIN_DATA_AGENT_DETAILS_INIT
  };
};

export const actionRequestAgentDetails = (payload) => {
  const { agencyCode, month, year } = payload;
  return {
    type: types.ADMIN_DATA_AGENT_DETAILS_REQUEST,
    payload: {
      agencyCode,
      month,
      year
    }
  };
};

export const actionReceiveAgentDetailsSuccess = (data) => {
  return {
    type: types.ADMIN_DATA_AGENT_DETAILS_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveAgentDetailsError = (data) => {
  return {
    type: types.ADMIN_DATA_AGENT_DETAILS_RECEIVE_ERROR,
    data
  };
};
