import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./RefreshAction";
import { apiGetRefreshToken } from "./RefreshApis";
import * as Types from "./RefreshTypes";

// Replace with your sagas
export function* getRefreshToken() {
  try {
    const response = yield call(apiGetRefreshToken);
    yield put(Actions.actionReceiveRefreshToken(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* RefreshToken() {
  yield takeLatest(Types.GET_REQUEST_REFRESH_TOKEN, getRefreshToken);
}
