import * as types from "./ManageCoachingTypes";

//Replace action name and update action types
export const actionManageCoachingInit = () => {
  return {
    type: types.MANAGE_COACHING_INIT
  };
};

export const actionRequestManageCoaching = (payload) => {
  const { code, startDate, endDate, showDeleted, page, count, overviewRequest } = payload;
  return {
    type: types.MANAGE_COACHING_REQUEST,
    payload: {
      code,
      startDate,
      endDate,
      showDeleted,
      overviewRequest,
      page,
      count
    }
  };
};

export const actionReceiveManageCoachingSuccess = (data) => {
  return {
    type: types.MANAGE_COACHING_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveManageCoachingError = (data) => {
  return {
    type: types.MANAGE_COACHING_RECEIVE_ERROR,
    data
  };
};
