import * as types from "./SubmitRecordAcknowledgementTypes";

//Replace action name and update action types
export const actionRequestSubmitRecordAcknowledgementInit = () => {
    return {
        type: types.REQUEST_SUBMIT_RECORDACKNOWLEDGEMENT_INIT
    };
};

export const actionRequestSubmitRecordAcknowledgement = (payload) => {
    const {
        id,
        module,
        accept,
        agentCode
    } = payload;
    return {
        type: types.REQUEST_SUBMIT_RECORDACKNOWLEDGEMENT,
        payload: {
            id,
            module,
            accept,
            agentCode
        }
    };
};

export const actionReceiveSubmitRecordAcknowledgementSuccess = (data) => {
    return {
        type: types.RECEIVE_SUBMIT_RECORDACKNOWLEDGEMENT_SUCCESS,
        data
    };
};

export const actionReceiveSubmitRecordAcknowledgementError = (data) => {
    return {
        type: types.RECEIVE_SUBMIT_RECORDACKNOWLEDGEMENT_ERROR,
        data
    };
};
