import * as types from "./AdminAgencyTypes";

//Replace action name and update action types
export const actionRequestAdminAgencyInit = () => {
  return {
    type: types.REQUEST_ADMIN_DATA_AGENCY_INIT
  };
};

export const actionRequestAdminAgency = (agencyStatus) => {
  return {
    type: types.REQUEST_ADMIN_DATA_AGENCY,
    agencyStatus
  };
};

export const actionReceiveAdminAgencySuccess = (data) => {
  return {
    type: types.RECEIVE_ADMIN_DATA_AGENCY_SUCCESS,
    data
  };
};

export const actionReceiveAdminAgencyError = (data) => {
  return {
    type: types.RECEIVE_ADMIN_DATA_AGENCY_ERROR,
    data
  };
};
