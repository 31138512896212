import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./JFVGetByIdActions";
import apiGetJFVById from "./JFVGetByIdApis";
import * as Types from "./JFVGetByIdTypes";

// Replace with your sagas
export function* jfvGetById(action) {
  try {
    const response = yield call(apiGetJFVById, action.id);
    yield put(Actions.actionRequestJFVByIdSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRequestJFVByIdError(err.response.data));
  }
}

export function* JFVGetById() {
  yield takeLatest(Types.REQUEST_GET_JFV_BY_ID, jfvGetById);
}
