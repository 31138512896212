import * as types from "./AgencyTypes";

//Replace action name and update action types
export const actionRequestAgencyInit = () => {
  return {
    type: types.DATA_AGENCY_REQUEST_INIT
  };
};

export const actionRequestAgency = (agentCode) => {
  return {
    type: types.DATA_AGENCY_REQUEST,
    agentCode
  };
};

export const actionReceiveAgencySuccess = (data) => {
  return {
    type: types.DATA_AGENCY_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveAgencyError = (data) => {
  return {
    type: types.DATA_AGENCY_RECEIVE_ERROR,
    data
  };
};
