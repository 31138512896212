import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ManageMeetingAction";
import apiGetManageMeeting from "./ManageMeetingApis";
import * as Types from "./ManageMeetingTypes";

// Replace with your sagas
export function* getDataManageMeeting(action) {
  try {
    const response = yield call(apiGetManageMeeting, action.payload);
    yield put(Actions.actionReceiveManageMeetingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveManageMeetingError(err.response.data));
  }
}

export function* ManageMeeting() {
  yield takeLatest(Types.MANAGE_MEETING_REQUEST, getDataManageMeeting);
}
