import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./AdminInfoForValidateAction";
import apiGetAdminInfoValidate from "./AdminInfoForValidateApis";
import * as Types from "./AdminInfoForValidateTypes";

// Replace with your sagas
export function* getDataAdminInfoValidate(action) {
  try {
    const response = yield call(apiGetAdminInfoValidate, action.code);
    yield put(Actions.actionReceiveAdminInfoValidateSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveAdminInfoValidateError(err.response.data));
  }
}

export function* AdminInfoValidate() {
  yield takeLatest(Types.REQUEST_ADMIN_INFO_VALIDATE, getDataAdminInfoValidate);
}
