import * as types from "./MeetingGetByIdTypes";

//Replace action name and update action types
export const actionRequestMeetingByIdInit = () => {
  return {
    type: types.REQUEST_GET_MEETING_BY_ID_INIT
  };
};

export const actionRequestMeetingById = (id) => {
  return {
    type: types.REQUEST_GET_MEETING_BY_ID,
    id
  };
};

export const actionRequestMeetingByIdSuccess = (data) => {
  return {
    type: types.RECEIVE_GET_MEETING_BY_ID_SUCCESS,
    data
  };
};

export const actionRequestMeetingByIdError = (data) => {
  return {
    type: types.RECEIVE_GET_MEETING_BY_ID_ERROR,
    data
  };
};
