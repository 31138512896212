import * as types from "./AgentDetailsTypes";

const INITIAL_STATE = {
  data: [],
  error: false,
  success: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADMIN_DATA_AGENT_DETAILS_INIT:
      return INITIAL_STATE;
    case types.ADMIN_DATA_AGENT_DETAILS_RECEIVE_SUCCESS:
      return {
        ...action.data,
        success: true,
        error: false
      };
    case types.ADMIN_DATA_AGENT_DETAILS_RECEIVE_ERROR:
      return {
        ...action.data,
        success: false,
        error: true
      };

    default:
      return state;
  }
};
