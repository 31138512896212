import * as types from "./ArchiveJFVTypes";

//Replace action name and update action types
export const actionReqArchiveJFVInit = () => {
  return {
    type: types.REQUEST_ARCHIVE_JFV_INIT
  };
};

export const actionReqArchiveJFV = (payload) => {
  const { code, startDate, endDate, status, page, count } = payload;
  return {
    type: types.REQUEST_ARCHIVE_JFV,
    payload: {
      code,
      startDate,
      endDate,
      status,
      page,
      count
    }
  };
};

export const actionRecArchiveJFVSuccess = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_JFV_SUCCESS,
    data
  };
};

export const actionRecArchiveJFVError = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_JFV_ERROR,
    data
  };
};
