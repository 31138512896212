import * as types from "./RefreshTypes";

//Replace action name and update action types
export const actionRequestRefreshToken = () => {
  return {
    type: types.GET_REQUEST_REFRESH_TOKEN
  };
};

export const actionReceiveRefreshToken = (data) => {
  return {
    type: types.GET_RECEIVE_REFRESH_TOKEN,
    data
  };
};
