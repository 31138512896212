import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetArchiveJFV = async (payload) => {
  const auth = Cookies.get("token");
  let params = {};
  let config = {};
  const { code, startDate, endDate, page, count, status } = payload;

  if (page) params["p"] = page;
  if (count) params["c"] = count;
  if (auth) {
    config = {
      params,
      headers: {
        "x-ior-auth": auth
      }
    };
  }

  let data = {};

  if (status === "all") {
    if (code)
      data = {
        code,
        startDate,
        endDate
      };
  } else {
    if (code)
      data = {
        code,
        startDate,
        endDate,
        status
      };
  }

  return await axiosInstance.post(`/jfv/getArchive`, data, config);
};

export default apiGetArchiveJFV;
