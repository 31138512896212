import * as types from "./AdminInfoTypes";

//Replace action name and update action types
export const actionRequestAdminInfoInit = () => {
  return {
    type: types.REQUEST_ADMIN_INFO_INIT
  };
};

export const actionRequestAdminInfo = (code) => {
  return {
    type: types.REQUEST_ADMIN_INFO,
    code
  };
};

export const actionReceiveAdminInfoSuccess = (data) => {
  return {
    type: types.RECEIVE_ADMIN_INFO_SUCCESS,
    data
  };
};

export const actionReceiveAdminInfoError = (data) => {
  return {
    type: types.RECEIVE_ADMIN_INFO_ERROR,
    data
  };
};
