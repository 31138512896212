import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetAssignmentList = async (agencyCode) => {
  const auth = Cookies.get("token");
  const leaderCode = Cookies.get("login_id");
  let data = {};
  let headers = {};
  if (agencyCode) data["agencyCode"] = agencyCode;
  if (leaderCode) data["leaderCode"] = leaderCode;
  if (auth)
    headers = {
      "x-ior-auth": auth
    };
  return await axiosInstance.post(`/common/getAssignmentList`, data, {
    headers
  });
};

export default apiGetAssignmentList;
