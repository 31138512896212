import * as Types from "./CreateMeetingTypes";

//Replace action name and update action types
export const actionCreateMeetingInit = () => {
  return {
    type: Types.CREATE_MEETING_INIT
  };
};

export const actionRequestCreateMeeting = (payload) => {
  return {
    type: Types.CREATE_MEETING_REQUEST,
    payload
  };
};

export const actionReceiveCreateMeetingSuccess = (data) => {
  return {
    type: Types.CREATE_MEETING_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveCreateMeetingError = (data) => {
  return {
    type: Types.CREATE_MEETING_RECEIVE_ERROR,
    data
  };
};
