import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./JFVUpdateAction";
import apiUpdateJFV from "./JFVUpdateApis";
import * as Types from "./JFVUpdateTypes";

// Replace with your sagas
export function* updateJFV(action) {
  try {
    const response = yield call(apiUpdateJFV, action.payload);
    yield put(Actions.actionReceiveUpdateJFVSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveUpdateJFVError(err.response.data));
  }
}

export function* UpdateJFV() {
  yield takeLatest(Types.UPDATE_JFV_REQUEST, updateJFV);
}
