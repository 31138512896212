import * as types from "./ArchiveCoachingGetByIdTypes";

export const INITIAL_STATE = {
  data: {},
  success: false,
  error: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_GET_COACHING_ARCHIVE_BY_ID_INIT:
      return INITIAL_STATE;
    case types.RECEIVE_GET_COACHING_ARCHIVE_BY_ID_SUCCESS:
      return {
        data: action.data.data,
        success: true,
        error: false
      };
    case types.RECEIVE_GET_COACHING_ARCHIVE_BY_ID_ERROR:
      return {
        success: false,
        error: true
      };

    default:
      return state;
  }
};
