import * as types from "./ArchiveCoachingGetByIdTypes";

//Replace action name and update action types
export const actionRequestCoachingArchiveByIdInit = () => {
  return {
    type: types.REQUEST_GET_COACHING_ARCHIVE_BY_ID_INIT
  };
};

export const actionRequestCoachingArchiveById = (id) => {
  return {
    type: types.REQUEST_GET_COACHING_ARCHIVE_BY_ID,
    id
  };
};

export const actionRequestCoachingArchiveByIdSuccess = (data) => {
  return {
    type: types.RECEIVE_GET_COACHING_ARCHIVE_BY_ID_SUCCESS,
    data
  };
};

export const actionRequestCoachingArchiveByIdError = (data) => {
  return {
    type: types.RECEIVE_GET_COACHING_ARCHIVE_BY_ID_ERROR,
    data
  };
};
