import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./CreateMeetingAction";
import apiCreateMeeting from "./CreateMeetingApis";
import * as Types from "./CreateMeetingTypes";

// Replace with your sagas
export function* createMeeting(action) {
  try {
    const response = yield call(apiCreateMeeting, action.payload);
    yield put(Actions.actionReceiveCreateMeetingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveCreateMeetingError(err.response.data));
  }
}

export function* CreateMeeting() {
  yield takeLatest(Types.CREATE_MEETING_REQUEST, createMeeting);
}
