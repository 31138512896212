import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./CoachingDownloadDocFormAction";
import apiCoachingDownloadDocForm from "./CoachingDownloadDocFormApis";
import * as Types from "./CoachingDownloadDocFormTypes";

// Replace with your sagas
export function* coachingDownloadDocForm(action) {
  try {
    const response = yield call(apiCoachingDownloadDocForm, action.id);
    yield put(Actions.actionReceiveCoachingDownloadDocFormSuccess(response));
  } catch (err) {
    yield put(Actions.actionReceiveCoachingDownloadDocFormError(err.response));
  }
}

export function* CoachingDownloadDocForm() {
  yield takeLatest(Types.REQUEST_DOWNLOAD_COACHING_DOC_FORM, coachingDownloadDocForm);
}
