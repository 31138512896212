import * as Types from "./MeetingUploadFormTypes";

//Replace action name and update action types
export const actionRequestMeetingUploadFormInit = () => {
  return {
    type: Types.REQUEST_MEETING_UPLOAD_FORM_INIT
  };
};

export const actionRequestMeetingUploadForm = (payload) => {
  const { file, id } = payload;
  return {
    type: Types.REQUEST_MEETING_UPLOAD_FORM,
    payload: {
      file,
      id
    }
  };
};

export const actionReceiveMeetingUploadFormSuccess = (data) => {
  return {
    type: Types.RECEIVE_MEETING_UPLOAD_FORM_SUCCESS,
    data
  };
};

export const actionReceiveMeetingUploadFormError = (data) => {
  return {
    type: Types.RECEIVE_MEETING_UPLOAD_FORM_ERROR,
    data
  };
};
