import * as types from "./JFVOverviewTypes";

//Replace action name and update action types
export const actionRequestLeaderJFVOverviewInit = () => {
  return {
    type: types.REQUEST_LEADER_JFV_OVERVIEW_INIT
  };
};

export const actionRequestLeaderJFVOverview = (payload) => {
  const { agentCode, agencyCode, startDate, endDate, page, count } = payload;
  return {
    type: types.REQUEST_LEADER_JFV_OVERVIEW,
    payload: {
      agentCode,
      agencyCode,
      startDate,
      endDate,
      page,
      count
    }
  };
};

export const actionReceiveLeaderJFVOverviewSuccess = (data) => {
  return {
    type: types.RECEIVE_LEADER_JFV_OVERVIEW_SUCCESS,
    data
  };
};

export const actionReceiveLeaderJFVOverviewError = (data) => {
  return {
    type: types.RECEIVE_LEADER_JFV_OVERVIEW_ERROR,
    data
  };
};
