import * as types from "./JFVGetByIdTypes";

//Replace action name and update action types
export const actionRequestJFVByIdInit = () => {
  return {
    type: types.REQUEST_GET_JFV_BY_ID_INIT
  };
};

export const actionRequestJFVById = (id) => {
  return {
    type: types.REQUEST_GET_JFV_BY_ID,
    id
  };
};

export const actionRequestJFVByIdSuccess = (data) => {
  return {
    type: types.RECEIVE_GET_JFV_BY_ID_SUCCESS,
    data
  };
};

export const actionRequestJFVByIdError = (data) => {
  return {
    type: types.RECEIVE_GET_JFV_BY_ID_ERROR,
    data
  };
};
