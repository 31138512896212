import * as types from "./ValidatePolicyTypes";

const INITIAL_STATE = {
  data: {},
  dataAdd: {},
  success: false,
  successAdd: false,
  error: false,
  errorAdd: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.VALIDATE_POLICY_INIT:
      return INITIAL_STATE;
    case types.VALIDATE_POLICY_RECEIVE_SUCCESS:
      return {
        ...state,
        data: action.data.data,
        success: true,
        error: false
      };
    case types.VALIDATE_POLICY_RECEIVE_ERROR:
      return {
        ...state,
        message: action.data,
        success: false,
        error: true
      };
    case types.VALIDATE_ADD_POLICY_INIT:
      return {
        ...state,
        dataAdd: {},
        successAdd: false,
        errorAdd: false
      };
    case types.VALIDATE_ADD_POLICY_SUCCESS:
      return {
        ...state,
        dataAdd: action.data.data,
        successAdd: true,
        errorAdd: false
      };
    case types.VALIDATE_ADD_POLICY_ERROR:
      return {
        ...state,
        dataAdd: action.data,
        successAdd: false,
        errorAdd: true
      };
    default:
      return state;
  }
};
