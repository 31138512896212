import * as Types from "./MeetingAbsentUploadTypes";

//Replace action name and update action types
export const actionMeetingAbsentUploadInit = () => {
  return {
    type: Types.MEETING_ABSENT_UPLOAD_INIT
  };
};

export const actionRequestMeetingAbsentUpload = (payload) => {
  const { file, id, agentCode, remarks } = payload;
  return {
    type: Types.MEETING_ABSENT_UPLOAD_REQUEST,
    payload: {
      file,
      id,
      agentCode,
      remarks
    }
  };
};

export const actionReceiveMeetingAbsentUploadSuccess = (data) => {
  return {
    type: Types.MEETING_ABSENT_UPLOAD_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveMeetingAbsentUploadError = (data) => {
  return {
    type: Types.MEETING_ABSENT_UPLOAD_RECEIVE_ERROR,
    data
  };
};
