import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./JFVDeleteAction";
import apiDeleteJFV from "./JFVDeleteApis";
import * as Types from "./JFVDeleteTypes";

// Replace with your sagas
export function* deleteJFV(action) {
  try {
    const response = yield call(apiDeleteJFV, action.payload);
    yield put(Actions.actionReceiveDeleteJFVSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveDeleteJFVError(err.response.data));
  }
}

export function* DeleteJFV() {
  yield takeLatest(Types.DELETE_JFV_REQUEST, deleteJFV);
}
