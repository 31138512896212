import * as Types from "./CreateJFVTypes";

//Replace action name and update action types
export const actionCreateJFVInit = () => {
  return {
    type: Types.CREATE_JFV_INIT
  };
};

export const actionRequestCreateJFV = (payload) => {
  return {
    type: Types.CREATE_JFV_REQUEST,
    payload
  };
};

export const actionReceiveCreateJFVSuccess = (data) => {
  return {
    type: Types.CREATE_JFV_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveCreateJFVError = (data) => {
  return {
    type: Types.CREATE_JFV_RECEIVE_ERROR,
    data
  };
};

export const actionRequestCalculateJFVScore = (payload) => {
  const { qna, score } = payload;
  return {
    type: Types.CALCULATE_JFV_SCORE,
    payload: { qna, score }
  };
};

export const actionRequestCalculateJFVScoreInit = () => {
  const payload = { qna: [], score: "" };
  return {
    type: Types.CALCULATE_JFV_SCORE_INIT,
    payload
  };
};
