import * as types from "./MeetingDeleteTypes";

//Replace action name and update action types
export const actionRequestMeetingByIdInit = () => {
  return {
    type: types.DELETE_MEETING_INIT
  };
};

export const actionRequestDeleteMeetingInit = () => {
  return {
    type: types.DELETE_MEETING_INIT
  };
};

export const actionRequestDeleteMeeting = (payload) => {
    const {
      id
    } = payload;
    return {
      type: types.DELETE_MEETING_REQUEST,
      payload: {
        id
      }
    };
  };
  
  export const actionReceiveDeleteMeetingSuccess = (data) => {
    return {
      type: types.DELETE_MEETING_RECEIVE_SUCCESS,
      data
    };
  };
  
  export const actionReceiveDeleteMeetingError = (data) => {
    return {
      type: types.DELETE_MEETING_RECEIVE_ERROR,
      data
    };
  };
  