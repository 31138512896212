import * as types from "./CoachingUploadTypes";

export const INITIAL_STATE = {
  success: false,
  error: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COACHING_UPLOAD_INIT:
      return INITIAL_STATE;
    case types.COACHING_UPLOAD_RECEIVE_SUCCESS:
      return {
        ...state,
        ...action.data,
        success: true,
        error: false
      };
    case types.COACHING_UPLOAD_RECEIVE_ERROR:
      return {
        ...state,
        ...action.data,
        success: false,
        error: true
      };
    default:
      return state;
  }
};
