import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MeetingDownloadDocAction";
import apiMeetingDownloadDoc from "./MeetingDownloadDocApis";
import * as Types from "./MeetingDownloadDocTypes";

// Replace with your sagas
export function* meetingDownloadDoc(action) {
  try {
    const response = yield call(apiMeetingDownloadDoc, action.id);
    yield put(Actions.actionReceiveMeetingDownloadDocSuccess(response));
  } catch (err) {
    yield put(Actions.actionReceiveMeetingDownloadDocError(err.response));
  }
}

export function* MeetingDownloadDoc() {
  yield takeLatest(Types.REQUEST_DOWNLOAD_MEETING_DOC, meetingDownloadDoc);
}
