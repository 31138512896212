import * as types from "./MeetingUpdateTypes";

const INITIAL_STATE = {
  success: false,
  error: false,
  data: {},
  attendance: []
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPDATE_MEETING_INIT:
      return INITIAL_STATE;
    case types.UPDATE_MEETING_RECEIVE_SUCCESS:
      return {
        ...state,
        ...action.data,
        success: true,
        error: false
      };
    case types.UPDATE_MEETING_RECEIVE_ERROR:
      return {
        ...state,
        ...action.data,
        success: false,
        error: true
      };
    default:
      return state;
  }
};
