import * as types from "./MeetingGetAbsentUploadTypes";

//Replace action name and update action types
export const actionRequestMeetingGetAbsentUploadInit = () => {
  return {
    type: types.REQUEST_MEETING_GET_ABSENT_UPLOAD_INIT
  };
};

export const actionRequestMeetingGetAbsentUpload = (id) => {
  return {
    type: types.REQUEST_MEETING_GET_ABSENT_UPLOAD,
    id
  };
};

export const actionRequestMeetingGetAbsentUploadSuccess = (data) => {
  return {
    type: types.RECEIVE_MEETING_GET_ABSENT_UPLOAD_SUCCESS,
    data
  };
};

export const actionRequestMeetingGetAbsentUploadError = (data) => {
  return {
    type: types.RECEIVE_MEETING_GET_ABSENT_UPLOAD_ERROR,
    data
  };
};
