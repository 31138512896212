import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./CoachingGetByIdActions";
import apiGetCoachingById from "./CoachingGetByIdApis";
import * as Types from "./CoachingGetByIdTypes";

// Replace with your sagas
export function* coachingGetById(action) {
  try {
    const response = yield call(apiGetCoachingById, action.id);
    yield put(Actions.actionRequestCoachingByIdSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRequestCoachingByIdError(err.response.data));
  }
}

export function* CoachingGetById() {
  yield takeLatest(Types.REQUEST_GET_COACHING_BY_ID, coachingGetById);
}
