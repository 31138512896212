import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetAdminInfo = async (code) => {
  const auth = Cookies.get("token");

  let params = {};
  let headers = {};

  if (code) params["code"] = code;
  if (auth) headers = { "x-ior-auth": auth };

  return await axiosInstance.get(`/admin/getAdminInfo`, {
    params,
    headers
  });
};

export default apiGetAdminInfo;
