import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MeetingUploadFormAction";
import apiMeetingUploadForm from "./MeetingUploadFormApis";
import * as Types from "./MeetingUploadFormTypes";

// Replace with your sagas
export function* uploadMeetingForm(action) {
  try {
    const response = yield call(apiMeetingUploadForm, action.payload);
    yield put(Actions.actionReceiveMeetingUploadFormSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveMeetingUploadFormError(err.response.data));
  }
}

export function* UploadMeetingForm() {
  yield takeLatest(Types.REQUEST_MEETING_UPLOAD_FORM, uploadMeetingForm);
}
