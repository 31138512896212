import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ArchiveMeetingAction";
import apiGetArchiveMeeting from "./ArchiveMeetingApis";
import * as Types from "./ArchiveMeetingTypes";

// Replace with your sagas
export function* archiveMeeting(action) {
  try {
    const response = yield call(apiGetArchiveMeeting, action.payload);
    yield put(Actions.actionRecArchiveMeetingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRecArchiveMeetingError(err.response.data));
  }
}

export function* ArchiveMeeting() {
  yield takeLatest(Types.REQUEST_ARCHIVE_MEETING, archiveMeeting);
}
