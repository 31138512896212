import * as types from "./JFVDownloadDocFormTypes";

const INITIAL_STATE = {
  success: false,
  error: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_DOWNLOAD_JFV_DOC_FORM_INIT:
      return INITIAL_STATE;
    case types.RECEIVE_DOWNLOAD_JFV_DOC_FORM_SUCCESS:
      return {
        ...state,
        ...action,
        success: true,
        error: false
      };
    case types.RECEIVE_DOWNLOAD_JFV_DOC_FORM_ERROR:
      return {
        ...state,
        ...action,
        success: false,
        error: true
      };
    default:
      return state;
  }
};
