import * as types from "./MeetingGetParticipantListTypes";

//Replace action name and update action types
export const actionRequestGetMeetingParticipantListsInit = () => {
  return {
    type: types.REQUEST_GET_MEETING_PARTICIPANT_LIST_INIT
  };
};

export const actionRequestGetMeetingParticipantLists = (payload) => {
  return {
    type: types.REQUEST_GET_MEETING_PARTICIPANT_LIST,
    payload
  };
};

export const actionRequestGetMeetingParticipantListsSuccess = (data) => {
  return {
    type: types.RECEIVE_GET_MEETING_PARTICIPANT_LIST_SUCCESS,
    data
  };
};

export const actionRequestGetMeetingParticipantListsError = (data) => {
  return {
    type: types.RECEIVE_GET_MEETING_PARTICIPANT_LIST_ERROR,
    data
  };
};
