import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MeetingDeleteAction";
import apiDeleteMeeting from "./MeetingDeleteApis";
import * as Types from "./MeetingDeleteTypes";

// Replace with your sagas
export function* deleteMeeting(action) {
  try {
    const response = yield call(apiDeleteMeeting, action.payload);
    yield put(Actions.actionReceiveDeleteMeetingSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveDeleteMeetingError(err.response.data));
  }
}

export function* DeleteMeeting() {
  yield takeLatest(Types.DELETE_MEETING_REQUEST, deleteMeeting);
}
