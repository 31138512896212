/* eslint-disable import/no-anonymous-default-export */
import Auth from "@utils/Auth";
import * as types from "./ValidateTypes";

const INITIAL_STATE = false;

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_RECEIVE_VALIDATE_TOKEN:
      if (action.data.success) {
        Auth.storeUserInfoToCookie(action.data.data);
        return true;
      } else {
        return false;
      }
    default:
      return state;
  }
};
