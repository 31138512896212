import * as Types from "./MeetingDownloadDocTypes";

//Replace action name and update action types
export const actionRequestMeetingDownloadDocInit = () => {
  return {
    type: Types.REQUEST_DOWNLOAD_MEETING_DOC_INIT
  };
};

export const actionRequestMeetingDownloadDoc = (id) => {
  return {
    type: Types.REQUEST_DOWNLOAD_MEETING_DOC,
    id
  };
};

export const actionReceiveMeetingDownloadDocSuccess = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_MEETING_DOC_SUCCESS,
    data
  };
};

export const actionReceiveMeetingDownloadDocError = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_MEETING_DOC_ERROR,
    data
  };
};
