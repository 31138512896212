import * as types from "./ArchiveMeetingGetByIdTypes";

//Replace action name and update action types
export const actionRequestMeetingArchiveByIdInit = () => {
  return {
    type: types.REQUEST_GET_MEETING_ARCHIVE_BY_ID_INIT
  };
};

export const actionRequestMeetingArchiveById = (id) => {
  return {
    type: types.REQUEST_GET_MEETING_ARCHIVE_BY_ID,
    id
  };
};

export const actionRequestMeetingArchiveByIdSuccess = (data) => {
  return {
    type: types.RECEIVE_GET_MEETING_ARCHIVE_BY_ID_SUCCESS,
    data
  };
};

export const actionRequestMeetingArchiveByIdError = (data) => {
  return {
    type: types.RECEIVE_GET_MEETING_ARCHIVE_BY_ID_ERROR,
    data
  };
};
