import * as types from "./NotificationTypes";

//Replace action name and update action types
export const actionRequestNotification = () => {
  return {
    type: types.REQUEST_NOTIFICATION
  };
};

export const actionReceiveNotificationSuccess = (data) => {
  return {
    type: types.RECEIVE_NOTIFICATION_SUCCESS,
    data
  };
};

export const actionReceiveNotificationError = (data) => {
  return {
    type: types.RECEIVE_NOTIFICATION_ERROR,
    data
  };
};
