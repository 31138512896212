import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./RecordAcknowledgementListActions";
import apiGetRecordAcknowledgementList from "./RecordAcknowledgementListApis";
import * as Types from "./RecordAcknowledgementListTypes";

// Replace with your sagas
export function* getRecordAcknowledgementList(action) {
    try {
        const response = yield call(apiGetRecordAcknowledgementList, action.agencyCode);
        yield put(Actions.actionReceiveRecordAcknowledgementListSuccess(response.data));
    } catch (err) {
        yield put(Actions.actionReceiveRecordAcknowledgementListError(err.response.data));
    }
}

export function* RecordAcknowledgementList() {
    yield takeLatest(Types.REQUEST_RECORDACKNOWLEDGEMENT_LIST, getRecordAcknowledgementList);
}
