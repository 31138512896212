import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./AuthAction";
import { apiGetAuthToken } from "./AuthApis";
import * as Types from "./AuthTypes";

// Replace with your sagas
export function* getAuthToken(action) {
  try {
    const response = yield call(apiGetAuthToken, action.payload);
    yield put(Actions.actionReceiveAuthTokenSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveAuthTokenError(err));
    console.log(err);
  }
}

export function* AuthToken() {
  yield takeLatest(Types.REQUEST_AUTH_TOKEN, getAuthToken);
}
