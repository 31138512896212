import cookies from "js-cookie";

import * as types from "./RefreshTypes";

const INITIAL_STATE = {
  success: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_RECEIVE_REFRESH_TOKEN:
      if (action.data.message === "Missing rt cookie") {
        return { success: false };
      } else {
        const token = action.data.data;
        const expires = new Date(new Date().getTime() + 15 * 60 * 1000);
        cookies.set("token", token, { expires });
        return { success: true };
      }
    default:
      return state;
  }
};
