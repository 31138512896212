/* eslint-disable import/prefer-default-export */
import { keyframes } from "styled-components";

export const rotation = (to = 360) => keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(${to}deg);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;
export const zoomOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.3);
  }
  to {
    opacity: 0;
    transform: scale(0.3);
  }
`;

export const dash = keyframes`
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

export const colors = {
  primary1: {
    color: "#FFFFFF",
    background: "#D31145"
  },
  primary2: {
    color: "#FFFFFF",
    background: "#596C80"
  },
  primary3: {
    color: "#FFFFFF",
    background: "#554344"
  },
  primary4: {
    color: "#FFFFFF",
    background: "#363E3F"
  },
  secondary1: {
    color: "#FFFFFF",
    background: "#E86487"
  },
  secondary2: {
    color: "#FFFFFF",
    background: "#FBBF56"
  },
  secondary3: {
    color: "#FFFFFF",
    background: "#97CB5D"
  },
  secondary4: {
    color: "#FFFFFF",
    background: "#46D3BD"
  },
  secondary5: {
    color: "#FFFFFF",
    background: "#22A8DA"
  },
  secondary6: {
    color: "#FFFFFF",
    background: "#9962D2"
  },
  tertiary1: {
    color: "#FFFFFF",
    background: "#F6CCD7"
  },
  tertiary2: {
    color: "#FFFFFF",
    background: "#B8123E"
  },
  tertiary3: {
    color: "#FFFFFF",
    background: "#F9E1B8"
  },
  tertiary4: {
    color: "#FFFFFF",
    background: "#C36418"
  },
  tertiary5: {
    color: "#FFFFFF",
    background: "#C6EB9E"
  },
  tertiary6: {
    color: "#FFFFFF",
    background: "#65962E"
  },
  tertiary7: {
    color: "#FFFFFF",
    background: "#A6E8DE"
  },
  tertiary8: {
    color: "#FFFFFF",
    background: "#158D7A"
  },
  tertiary9: {
    color: "#FFFFFF",
    background: "#A9DCEF"
  },
  tertiary10: {
    color: "#FFFFFF",
    background: "#0A6E93"
  },
  tertiary11: {
    color: "#FFFFFF",
    background: "#D7BFF0"
  },
  tertiary12: {
    color: "#FFFFFF",
    background: "#551893"
  },
  support1: {
    color: "#FFFFFF",
    background: "#FF0000"
  },
  support2: {
    color: "#FFFFFF",
    background: "#FF5500"
  },
  support3: {
    color: "#FFFFFF",
    background: "#405B70"
  },
  support4: {
    color: "#FFFFFF",
    background: "#769FAB"
  },
  black: {
    color: "#FFFFFF",
    background: "#000000"
  },
  white: {
    color: "#333",
    background: "#FFFFFF"
  }
};
