import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Routes from "@routes/Routes";
import Loader from "@components/Loader/Loader";
import { ModalSessionExpired } from "@components/Modal/ModalSessionExpired";
import { mapDispatchToProps, mapStateToProps } from "@redux-state/index";
import { useHandleIdle } from "@hooks/useHandleIdle";
import "./App.css";

/**
 * App file is parent dir as react app
 * App contains routes on the upper
 * are loader and modal session expired
 * also wrapped by div
 *
 * @namespace {React.Component} App
 * @param {Object} props                              - an object required as state
 * @param {Auth} props.auth                           - redux state which is an object
 * @param {Boolean} props.loader                      - redux state that to handle loading
 * @param {Function} props.dispatchRefreshToken       - actionRequestRefreshToken()
 * @param {Function} props.dispatchReqAuthInit        - actionRequestAuthTokenInit()
 * @param {Function} props.dispatchMenuInit           - actionRequestGetMenuInit()
 * @param {Function} props.dispatchReqAgencyDataInit  - actionRequestAgencyInit()
 * @param {Function} props.dispatchReqAgentInfoInit   - actionRequestAgentInfoInit()
 */

const App = (props) => {
  // props come from redux state
  // these required as user authentication
  // and also dispatch action
  const {
    loader,
    dispatchReqAuthInit,
    dispatchMenuInit,
    dispatchReqAgencyDataInit,
    dispatchReqAgentInfoInit
  } = props;

  // all the funcionality will end
  // while user is inactive for 15 minutes
  // and showing a modal session expired
  const { sessionExpired } = useHandleIdle({
    dispatchReqAuthInit,
    dispatchMenuInit,
    dispatchReqAgencyDataInit,
    dispatchReqAgentInfoInit
  });

  return (
    <div dir="ltr">
      {loader ? <Loader /> : null}
      {sessionExpired && <ModalSessionExpired />}
      <Routes />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

App.propTypes = {
  auth: PropTypes.shape({
    loginData: PropTypes.string,
    exp: PropTypes.string,
    success: PropTypes.bool
  }).isRequired,
  loader: PropTypes.bool.isRequired,
  dispatchRefreshToken: PropTypes.func.isRequired,
  dispatchReqAuthInit: PropTypes.func.isRequired,
  dispatchMenuInit: PropTypes.func.isRequired,
  dispatchReqAgencyDataInit: PropTypes.func.isRequired,
  dispatchReqAgentInfoInit: PropTypes.func.isRequired
};
