import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./CoachingUploadAction";
import apiCoachingUpload from "./CoachingUploadApis";
import * as Types from "./CoachingUploadTypes";

// Replace with your sagas
export function* uploadCoaching(action) {
  try {
    const response = yield call(apiCoachingUpload, action.payload);
    yield put(Actions.actionReceiveCoachingUploadSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveCoachingUploadError(err.response.data));
  }
}

export function* UploadCoaching() {
  yield takeLatest(Types.COACHING_UPLOAD_REQUEST, uploadCoaching);
}
