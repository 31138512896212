import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MeetingGetByIdActions";
import apiGetMeetingById from "./MeetingGetByIdApis";
import * as Types from "./MeetingGetByIdTypes";

// Replace with your sagas
export function* meetingGetById(action) {
  try {
    const response = yield call(apiGetMeetingById, action.id);
    yield put(Actions.actionRequestMeetingByIdSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRequestMeetingByIdError(err.response.data));
  }
}

export function* MeetingGetById() {
  yield takeLatest(Types.REQUEST_GET_MEETING_BY_ID, meetingGetById);
}
