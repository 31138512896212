import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./SubmitAssignmentActions";
import apiSubmitAssignment from "./SubmitAssignmentApis";
import * as Types from "./SubmitAssignmentTypes";

// Replace with your sagas
export function* submitAssignment(action) {
  try {
    const response = yield call(apiSubmitAssignment, action.payload);
    yield put(Actions.actionReceiveSubmitAssignmentSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveSubmitAssignmentError(err.response.data));
  }
}

export function* SubmitAssignment() {
  yield takeLatest(Types.REQUEST_SUBMIT_ASSIGNMENT, submitAssignment);
}
