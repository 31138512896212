import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiCreateMeeting = async (payload) => {
  const {
    agencyCode, //
    agencyName,
    leaderCode,
    meetingDate,
    startTime,
    endTime,
    type,
    status,
    absent,
    present,
    chair,
    topics
  } = payload;
  const auth = Cookies.get("token");
  let config;
  if (auth) {
    config = {
      headers: {
        "x-ior-auth": auth
      }
    };
  }

  const result = await axiosInstance.post(
    `/meeting/create`,
    {
      agencyCode,
      agencyName,
      leaderCode,
      meetingDate,
      startTime,
      endTime,
      type,
      status,
      absent,
      present,
      chair,
      topics
    },
    config
  );
  return result;
};

export default apiCreateMeeting;
