import * as types from "./ManageJFVTypes";

//Replace action name and update action types
export const actionManageJFVInit = () => {
  return {
    type: types.MANAGE_JFV_INIT
  };
};

export const actionRequestManageJFV = (payload) => {
  const { code, startDate, endDate, page, count, showDeleted } = payload;
  return {
    type: types.MANAGE_JFV_REQUEST,
    payload: {
      code,
      startDate,
      endDate,
      page,
      count,
      showDeleted
    }
  };
};

export const actionReceiveManageJFVSuccess = (data) => {
  return {
    type: types.MANAGE_JFV_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveManageJFVError = (data) => {
  return {
    type: types.MANAGE_JFV_RECEIVE_ERROR,
    data
  };
};
