import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetLeaderList = async (agentCode) => {
  const auth = Cookies.get("token");
  let data = {};
  let headers = {};
  data["companyCode"] = "011";
  data["countryCode"] = "011";
  if (agentCode) data["agentCode"] = agentCode;
  if (auth)
    headers = {
      "x-ior-auth": auth
    };
  return await axiosInstance.post(`/common/getLeaderList`, data, {
    headers
  });
};

export default apiGetLeaderList;
