import styled from "styled-components";

export const LoaderStyled = styled.div`
  .spinnerContainer {
    top: 0;
    position: fixed;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: #ffffff9e;
  }

  .loaderText {
    padding-top: 2rem;
    font-size: 17px;
    font-weight: 600;
  }
`;
