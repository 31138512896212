import * as types from "./GetAssignmentToTypes";

//Replace action name and update action types
export const actionRequestGetAssignmentToInit = () => {
  return {
    type: types.REQUEST_GET_ASSIGNMENT_TO_INIT
  };
};

export const actionRequestGetAssignmentTo = (payload) => {
  return {
    type: types.REQUEST_GET_ASSIGNMENT_TO,
    payload
  };
};

export const actionReceiveGetAssignmentToSuccess = (data) => {
  return {
    type: types.RECEIVE_GET_ASSIGNMENT_TO_SUCCESS,
    data
  };
};

export const actionReceiveGetAssignmentToError = (data) => {
  return {
    type: types.RECEIVE_GET_ASSIGNMENT_TO_ERROR,
    data
  };
};
