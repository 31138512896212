import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./CoachingUploadFormAction";
import apiCoachingUploadForm from "./CoachingUploadFormApis";
import * as Types from "./CoachingUploadFormTypes";

// Replace with your sagas
export function* uploadCoachingForm(action) {
  try {
    const response = yield call(apiCoachingUploadForm, action.payload);
    yield put(Actions.actionReceiveCoachingUploadFormSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveCoachingUploadFormError(err.response.data));
  }
}

export function* UploadCoachingForm() {
  yield takeLatest(Types.REQUEST_COACHING_UPLOAD_FORM, uploadCoachingForm);
}
