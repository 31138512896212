import * as types from "./ArchiveCoachingTypes";

export const INITIAL_STATE = {
  data: [],
  totalCount: 0,
  pageCount: 0,
  success: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_ARCHIVE_COACHING_INIT:
      return INITIAL_STATE;
    case types.RECEIVE_ARCHIVE_COACHING_SUCCESS:
      return {
        data: action.data.data.data,
        totalCount: action.data.data.totalCount,
        pageCount: action.data.data.pageCount,
        success: true
      };
    case types.RECEIVE_ARCHIVE_COACHING_ERROR:
      return {
        ...state,
        success: false
      };
    default:
      return state;
  }
};
