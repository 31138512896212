import * as types from "./ValidateTypes";

//Replace action name and update action types
export const actionRequestValidateToken = (data) => {
  return {
    type: types.GET_REQUEST_VALIDATE_TOKEN,
    data
  };
};

export const actionReceiveValidateToken = (data) => {
  return {
    type: types.GET_RECEIVE_VALIDATE_TOKEN,
    data
  };
};
