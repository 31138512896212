import * as Types from "./OwnJFVUploadTypes";

//Replace action name and update action types
export const actionReqOwnJFVUploadInit = () => {
  return {
    type: Types.REQUEST_OWN_JFV_UPLOAD_INIT
  };
};

export const actionReqOwnJFVUpload = (payload) => {
  const { file, id } = payload;
  return {
    type: Types.REQUEST_OWN_JFV_UPLOAD,
    payload: {
      file,
      id
    }
  };
};

export const actionRecOwnJFVUploadSuccess = (data) => {
  return {
    type: Types.RECEIVE_OWN_JFV_SUCCESS,
    data
  };
};

export const actionRecOwnJFVUploadError = (data) => {
  return {
    type: Types.RECEIVE_OWN_JFV_ERROR,
    data
  };
};
