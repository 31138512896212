import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MeetingAbsentUploadAction";
import apiMeetingAbsentUpload from "./MeetingAbsentUploadApis";
import * as Types from "./MeetingAbsentUploadTypes";

// Replace with your sagas
export function* uploadAbsentMeeting(action) {
  try {
    const response = yield call(apiMeetingAbsentUpload, action.payload);
    yield put(Actions.actionReceiveMeetingAbsentUploadSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveMeetingAbsentUploadError(err.response.data));
  }
}

export function* UploadAbsentMeeting() {
  yield takeLatest(Types.MEETING_ABSENT_UPLOAD_REQUEST, uploadAbsentMeeting);
}
