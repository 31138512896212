import { axiosInstance } from "@network/apis";
import Auth from "@utils/Auth";

// Replace endpoint and change api name
export const apiGetMenu = async () => {
  const auth = Auth.isValidate();
  const config = {
    headers: {
      "x-ior-auth": auth
    }
  };
  const result = await axiosInstance.get(`/auth/getmenu`, config);
  return result;
};
