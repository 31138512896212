import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./JFVGetArchiveAttachmentAction";
import apiDownloadArchiveAttachmentForm from "./JFVGetArchiveAttachmentApis";
import * as Types from "./JFVGetArchiveAttachmentTypes";

// Replace with your sagas
export function* downloadArchiveAttachment(action) {
  try {
    const response = yield call(apiDownloadArchiveAttachmentForm, action.payload);
    yield put(Actions.actionReceiveDownloadArchiveAttachmentSuccess(response));
  } catch (err) {
    yield put(Actions.actionReceiveDownloadArchiveAttachmentError(err.response));
  }
}

export function* ArchiveAttachment() {
  yield takeLatest(Types.REQUEST_DOWNLOAD_ARCHIVE_ATTACHMENT, downloadArchiveAttachment);
}
