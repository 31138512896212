import { configureStore } from "@reduxjs/toolkit";

import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers/index";
import { watchSagas } from "./sagas";

const saga = createSagaMiddleware();

export const setupStore = (preloadedState) => {
  return configureStore({
    ...(!preloadedState && preloadedState),
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        thunk: true,
        serializableCheck: false
      }),
      saga
    ],
    devTools: process.env.NODE_ENV !== "production"
  });
};

const store = setupStore();

saga.run(watchSagas);

export default store;
