import * as types from "./ManageMeetingTypes";

//Replace action name and update action types
export const actionManageMeetingInit = () => {
  return {
    type: types.MANAGE_MEETING_INIT
  };
};

export const actionRequestManageMeeting = (payload) => {
  const { authorization, code, startDate, endDate, page, count, showDeleted, overviewRequest } =
    payload;
  return {
    type: types.MANAGE_MEETING_REQUEST,
    payload: {
      authorization,
      code,
      startDate,
      endDate,
      page,
      count,
      showDeleted,
      overviewRequest
    }
  };
};

export const actionReceiveManageMeetingSuccess = (data) => {
  return {
    type: types.MANAGE_MEETING_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveManageMeetingError = (data) => {
  return {
    type: types.MANAGE_MEETING_RECEIVE_ERROR,
    data
  };
};
