import * as Types from "./CoachingUpdateTypes";

//Replace action name and update action types
export const actionUpdateCoachingInit = () => {
  return {
    type: Types.UPDATE_COACHING_INIT
  };
};

export const actionRequestUpdateCoaching = (payload) => {
  const {
    id,
    type,
    // twelveWeekCoaching,
    coachId,
    coachName,
    agencyCode,
    agencyName,
    coacheeId,
    coacheeName,
    date,
    startTime,
    endTime,
    topic,
    reviewPrevSession,
    activityManagement,
    agenda,
    keyCoachingPoints,
    actionPlan,
    coachFeedback,
    coacheeFeedback,
    status,
    deletedBy,
    deletedByName
  } = payload;
  return {
    type: Types.UPDATE_COACHING_REQUEST,
    payload: {
      id,
      type,
      // twelveWeekCoaching,
      coachId,
      coachName,
      agencyCode,
      agencyName,
      coacheeId,
      coacheeName,
      date,
      startTime,
      endTime,
      topic,
      reviewPrevSession,
      activityManagement,
      agenda,
      keyCoachingPoints,
      actionPlan,
      coachFeedback,
      coacheeFeedback,
      status,
      deletedBy,
      deletedByName
    }
  };
};

export const actionReceiveUpdateCoachingSuccess = (data) => {
  return {
    type: Types.UPDATE_COACHING_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveUpdateCoachingError = (data) => {
  return {
    type: Types.UPDATE_COACHING_RECEIVE_ERROR,
    data
  };
};
