import * as types from "./ValidateTimingTypes";

//Replace action name and update action types
export const actionValidateTimingInit = () => {
  return {
    type: types.VALIDATE_TIMING_INIT
  };
};

export const actionRequestValidateTiming = (payload) => {
  const { id, startDate, endDate, startTime, endTime } = payload;
  return {
    type: types.VALIDATE_TIMING_REQUEST,
    payload: {
      id,
      startDate,
      endDate,
      startTime,
      endTime
    }
  };
};

export const actionReceiveValidateTimingSuccess = (data) => {
  return {
    type: types.VALIDATE_TIMING_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveValidateTimingError = (data) => {
  return {
    type: types.VALIDATE_TIMING_RECEIVE_ERROR,
    data
  };
};
