import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./AdminAgencyAction";
import apiGetAdminAgency from "./AdminAgencyApis";
import * as Types from "./AdminAgencyTypes";

// Replace with your sagas
export function* getDataAdminAgency(action) {
  try {
    const response = yield call(apiGetAdminAgency, action.agencyStatus);
    yield put(Actions.actionReceiveAdminAgencySuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveAdminAgencyError(err));
  }
}

export function* AdminAgency() {
  yield takeLatest(Types.REQUEST_ADMIN_DATA_AGENCY, getDataAdminAgency);
}
