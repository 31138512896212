import { saveBufferArray } from "@helpers/saveBufferArray";
import * as types from "./JFVGetArchiveAttachmentTypes";
// import { mockedResult } from "./JFVGetArchiveAttachment.mock";

const INITIAL_STATE = {
  success: false,
  error: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_DOWNLOAD_ARCHIVE_ATTACHMENT_INIT:
      return INITIAL_STATE;
    case types.RECEIVE_DOWNLOAD_ARCHIVE_ATTACHMENT_SUCCESS:
      let { success, data } = action.data.data;
      if (success) {
        saveBufferArray(data.filename, data.data.data);
      }
      return {
        ...state,
        data: action.data.filename,
        success: true,
        error: false
      };
    case types.RECEIVE_DOWNLOAD_ARCHIVE_ATTACHMENT_ERROR:
      return {
        ...state,
        data: action.data.data,
        success: false,
        error: true
      };
    default:
      return state;
  }
};
