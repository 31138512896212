import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MeetingGetAbsentUploadActions";
import apiGetMeetingAbsentUpload from "./MeetingGetAbsentUploadApis";
import * as Types from "./MeetingGetAbsentUploadTypes";

// Replace with your sagas
export function* meetingGetAbsentUpload(action) {
  try {
    const response = yield call(apiGetMeetingAbsentUpload, action.id);
    yield put(Actions.actionRequestMeetingGetAbsentUploadSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRequestMeetingGetAbsentUploadError(err.response.data));
  }
}

export function* MeetingGetAbsentUpload() {
  yield takeLatest(Types.REQUEST_MEETING_GET_ABSENT_UPLOAD, meetingGetAbsentUpload);
}
