import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./MenuActions";
import { apiGetMenu } from "./MenuApis";
import * as Types from "./MenuTypes";

// Replace with your sagas
export function* getGetMenu() {
  try {
    const response = yield call(apiGetMenu);
    yield put(Actions.actionReceiveGetMenuSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveGetMenuError(err));
    console.log(err);
  }
}

export function* GetMenu() {
  yield takeLatest(Types.REQUEST_GET_MENU, getGetMenu);
}
