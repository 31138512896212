import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetArchiveCoachingExport = async (payload) => {
  const auth = Cookies.get("token");
  let config = {};
  const { code, startDate, endDate, status } = payload;
  if (auth) {
    config = {
      headers: {
        "x-ior-auth": auth
      },
      responseType: "blob"
    };
  }

  let data = {
    code,
    startDate,
    endDate,
    status
  };

  let result;
  await axiosInstance
    .post(`/coaching/exportExcel`, data, config)
    .then((response) => {
      const headerLine = response.headers["content-disposition"];
      const filename = headerLine
        .split(";")
        .find((n) => n.includes("filename="))
        .replace("filename=", "")
        .trim();
      let url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      link.click();
      result = response;
    })
    .catch((error) => console.log(error));
  return result;
};

export default apiGetArchiveCoachingExport;
