import * as types from "./CoachingDownloadDocTypes";

const INITIAL_STATE = {
  success: false,
  error: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_DOWNLOAD_COACHING_DOC_INIT:
      return INITIAL_STATE;
    case types.RECEIVE_DOWNLOAD_COACHING_DOC_SUCCESS:
      return {
        ...state,
        ...action,
        success: true,
        error: false
      };
    case types.RECEIVE_DOWNLOAD_COACHING_DOC_ERROR:
      return {
        ...state,
        ...action,
        success: false,
        error: true
      };
    default:
      return state;
  }
};
