import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ArchiveJFVExportAction";
import apiGetArchiveJFVExport from "./ArchiveJFVExportApis";
import * as Types from "./ArchiveJFVExportTypes";

// Replace with your sagas
export function* archiveJFVExport(action) {
  try {
    const response = yield call(apiGetArchiveJFVExport, action.payload);
    yield put(Actions.actionRecArchiveJFVExportSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionRecArchiveJFVExportError(err.response.data));
  }
}

export function* ArchiveJFVExport() {
  yield takeLatest(Types.REQUEST_ARCHIVE_JFV_EXPORT, archiveJFVExport);
}
