import Auth from "@utils/Auth";
import { axiosInstance } from "@network/apis";

// Replace endpoint and change api name
const apiGetAgentInfo = async (agentCode) => {
  const auth = Auth.isValidate();
  let data = {};
  data["countryCode"] = "011";
  if (agentCode) data["agentCode"] = agentCode;
  const config = {
    headers: {
      "x-ior-auth": auth
    }
  };
  const result = await axiosInstance.post(`/agent/getAgentInfo`, data, config);
  return result;
};

export default apiGetAgentInfo;
