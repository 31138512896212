import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetMeetingParticipantLists = async (payload) => {
  const auth = Cookies.get("token");
  let config = {};
  if (auth) {
    config = {
      headers: {
        "x-ior-auth": auth
      }
    };
  }
  return await axiosInstance.post(`meeting/getMeetingParticipantList`, payload, config);
};

export default apiGetMeetingParticipantLists;
