import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./AgencyAction";
import apiGetAgency from "./AgencyApis";
import * as Types from "./AgencyTypes";

// Replace with your sagas
export function* getDataAgency(action) {
  try {
    const response = yield call(apiGetAgency, action.agentCode);
    yield put(Actions.actionReceiveAgencySuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveAgencyError(err.response.data));
  }
}

export function* Agency() {
  yield takeLatest(Types.DATA_AGENCY_REQUEST, getDataAgency);
}
