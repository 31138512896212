import * as types from "./PolicyDetailTypes";

//Replace action name and update action types
export const actionPolicyDetailInit = () => {
  return {
    type: types.INIT_POLICY_DETAIL
  };
};

export const actionAddPolicyDetailInit = () => {
  return {
    type: types.INIT_ADD_POLICY_DETAIL
  };
};

export const actionRequestPolicyDetail = (data) => {
  return {
    type: types.REQUEST_POLICY_DETAIL,
    data
  };
};

export const actionRequestAddPolicyDetail = (data) => {
  return {
    type: types.REQUEST_ADD_POLICY_DETAIL,
    data
  };
};

export const actionReceivePolicyDetailSuccess = (data) => {
  return {
    type: types.RECEIVE_SUCCESS_POLICY_DETAIL,
    data
  };
};

export const actionReceiveAddPolicyDetailSuccess = (data) => {
  return {
    type: types.RECEIVE_SUCCESS_ADD_POLICY_DETAIL,
    data
  };
};

export const actionReceivePolicyDetailError = (data) => {
  return {
    type: types.RECEIVE_ERROR_POLICY_DETAIL,
    data
  };
};

export const actionReceiveAddPolicyDetailError = (data) => {
  return {
    type: types.RECEIVE_ERROR_ADD_POLICY_DETAIL,
    data
  };
};
