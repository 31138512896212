/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import {
  actionAddPolicyDetailInit,
  actionPolicyDetailInit,
  actionRequestAddPolicyDetail,
  actionRequestPolicyDetail
} from "./common/PolicyDetails/PolicyDetailActions";
import {
  actionCoachingUploadInit,
  actionRequestCoachingUpload
} from "./Coaching/CoachingUpload/CoachingUploadAction";
import {
  actionCreateCoachingInit,
  actionRequestCreateCoaching
} from "./Coaching/CreateCoaching/CreateCoachingAction";
import {
  actionCreateJFVInit,
  actionRequestCalculateJFVScoreInit,
  actionRequestCreateJFV
} from "./JFV/JFVElectronicForm/CreateJFVAction";
import {
  actionCreateMeetingInit,
  actionRequestCreateMeeting
} from "./Meeting/MeetingElectronicForm/CreateMeetingAction";
import {
  actionManageCoachingInit,
  actionRequestManageCoaching
} from "./Coaching/ManageCoaching/ManageCoachingAction";
import { actionManageJFVInit, actionRequestManageJFV } from "./JFV/ManageJFV/ManageJFVAction";
import {
  actionManageMeetingInit,
  actionRequestManageMeeting
} from "./Meeting/ManageMeeting/ManageMeetingAction";
import {
  actionMeetingAbsentUploadInit,
  actionRequestMeetingAbsentUpload
} from "./Meeting/MeetingAbsentUpload/MeetingAbsentUploadAction";
import {
  actionMeetingUploadInit,
  actionRequestMeetingUpload
} from "./Meeting/MeetingUpload/MeetingUploadAction";
import {
  actionReqArchiveCoaching,
  actionReqArchiveCoachingInit
} from "./Archive/ArchiveCoaching/ArchiveCoachingAction";
import {
  actionReqArchiveJFV,
  actionReqArchiveJFVInit
} from "./Archive/ArchiveJFV/ArchiveJFVAction";
import {
  actionReqArchiveMeeting,
  actionReqArchiveMeetingInit
} from "./Archive/ArchiveMeeting/ArchiveMeetingAction";
import { actionRequestAgency, actionRequestAgencyInit } from "./common/Agency/AgencyAction";
import {
  actionRequestAgentDetails,
  actionRequestAgentDetailsInit
} from "./admin/AgentDetailsTnC/AgentDetailsAction";
import {
  actionRequestAgentInfo,
  actionRequestAgentInfoInit
} from "./common/AgentInfo/AgencyAction";
import {
  actionRequestCoachingById,
  actionRequestCoachingByIdInit
} from "./Coaching/CoachingGetById/CoachingGetByIdActions";
import {
  actionRequestCoachingUploadForm,
  actionRequestCoachingUploadFormInit
} from "./Coaching/CoachingUploadForm/CoachingUploadFormAction";
import { actionRequestGetMenu, actionRequestGetMenuInit } from "./Menu/MenuActions";
import { actionRequestJFVById, actionRequestJFVByIdInit } from "./JFV/JFVGetById/JFVGetByIdActions";
import {
  actionRequestLeaderAssignment,
  actionRequestLeaderAssignmentInit
} from "./common/LeaderAssignment/LeaderAssignmentActions";
import {
  actionRequestRecordAcknowledgementList
} from "./common/RecordAcknowledgement/RecordAcknowledgementListActions";
import {
  actionRequestLeaderJFVOverview,
  actionRequestLeaderJFVOverviewInit
} from "./LeaderOverview/JFVOverview/JFVOverviewAction";
import {
  actionRequestMeetingById,
  actionRequestMeetingByIdInit
} from "./Meeting/MeetingGetById/MeetingGetByIdActions";
import {
  actionRequestMeetingGetAbsentUpload,
  actionRequestMeetingGetAbsentUploadInit
} from "./Meeting/MeetingGetAbsentUpload/MeetingGetAbsentUploadActions";
import {
  actionRequestMeetingUploadForm,
  actionRequestMeetingUploadFormInit
} from "./Meeting/MeetingUploadForm/MeetingUploadFormAction";
import {
  actionRequestSubmitAssignment,
  actionRequestSubmitAssignmentInit
} from "./common/SubmitAssignment/SubmitAssignmentActions";
import {
  actionRequestSubmitRecordAcknowledgement,
  actionRequestSubmitRecordAcknowledgementInit
} from "./common/SubmitRecordAcknowledgement/SubmitRecordAcknowledgementActions";
import {
  actionRequestUpdateCoaching,
  actionUpdateCoachingInit
} from "./Coaching/CoachingUpdate/CoachingUpdateAction";
import { actionRequestUpdateJFV, actionUpdateJFVInit } from "./JFV/JFVUpdate/JFVUpdateAction";
import {
  actionRequestUpdateMeeting,
  actionUpdateMeetingInit
} from "./Meeting/MeetingUpdate/MeetingUpdateAction";
import {
  actionRequestValidateTiming,
  actionValidateTimingInit
} from "./common/ValidateTiming/ValidateTimingAction";
import {
  actionRequestGetAssignmentFrom,
  actionRequestGetAssignmentFromInit
} from "./common/getAssignmentFrom/GetAssignmentFromAction";
import {
  actionRequestGetAssignmentTo,
  actionRequestGetAssignmentToInit
} from "./common/getAssignmentTo/GetAssignmentToAction";
import {
  actionRequestAdminInfo,
  actionRequestAdminInfoInit
} from "./admin/AdminInfo/AdminInfoAction";
import {
  actionRequestAdminInfoValidate,
  actionRequestAdminInfoValidateInit
} from "./admin/AdminInfoForValidate/AdminInfoForValidateAction";
import {
  actionRequestGetMeetingParticipantLists,
  actionRequestGetMeetingParticipantListsInit
} from "./Meeting/MeetingGetParticipantLists/MeetingGetParticipantListActions";
import {
  actionRequestValidateAddPolicy,
  actionRequestValidateAddPolicyInit,
  actionRequestValidatePolicy,
  actionValidatePolicyInit
} from "./common/ValidatePolicy/ValidatePolicyAction";
import {
  actionRequestCoachingArchiveById,
  actionRequestCoachingArchiveByIdInit
} from "./Archive/ArchiveCoaching/ArchiveCoachingGetById/ArchiveCoachingGetByIdActions";
import {
  actionRequestJfvArchiveById,
  actionRequestJfvArchiveByIdInit
} from "./Archive/ArchiveJFV/ArchiveJFVGetById/ArchiveJFVGetByIdActions";
import {
  actionRequestMeetingArchiveById,
  actionRequestMeetingArchiveByIdInit
} from "./Archive/ArchiveMeeting/ArchiveMeetingGetById/ArchiveMeetingGetByIdActions";
import {
  actionRequestDownloadArchiveAttachment,
  actionRequestDownloadArchiveAttachmentInit
} from "./JFV/JFVGetArchiveAttachment/JFVGetArchiveAttachmentAction";
import {
  setAttandances,
  setDataAgentLists,
  setDataAgentTier2Lists,
  setDataAgentTier3Lists
} from "./Meeting/SetParticipantSlice/SetParticipantSlice";

import { actionReqArchiveCoachingExport } from "./Archive/ArchiveCoaching/ArchiveCoachingExport/ArchiveCoachingExportAction";
import { actionReqArchiveJFVDownloadDoc } from "./Archive/ArchiveJFV/ArchiveJFVDownloadDoc/ArchiveJFVDownloadDocAction";
import { actionReqArchiveJFVExport } from "./Archive/ArchiveJFV/ArchiveJFVExport/ArchiveJFVExportAction";
import { actionReqArchiveMeetingExport } from "./Archive/ArchiveMeeting/ArchiveMeetingExport/ArchiveMeetingExportAction";
import { actionReqOwnJFVUpload } from "./JFV/OwnJFVUpload/OwnJFVUploadAction";
import { actionRequestAdminAgency } from "./admin/AdminAgency/AdminAgencyAction";
import { actionRequestAssignmentList } from "./common/E-acknowledment/AssignmentListAction";
import { actionRequestAuthTokenInit } from "./Auth/AuthAction";
import { actionRequestCoachingDownloadDoc } from "./Coaching/CoachingDownloadDoc/CoachingDownloadDocAction";
import { actionRequestCoachingDownloadDocForm } from "./Coaching/CoachingDownloadDocForm/CoachingDownloadDocFormAction";
import { actionRequestDownloadMeetingAbsent } from "./Meeting/MeetingAbsentDownload/MeetingAbsentDownloadAction";
import { actionRequestJfvDownloadDocForm } from "./JFV/JFVDownloadDocForm/JFVDownloadDocFormAction";
import { actionRequestLeaderList } from "./common/LeaderList/LeaderListAction";
import { actionRequestMeetingDownloadDoc } from "./Meeting/MeetingDownloadDoc/MeetingDownloadDocAction";
import { actionRequestMeetingDownloadDocForm } from "./Meeting/MeetingDownloadDocForm/MeetingDownloadDocFormAction";
import { actionRequestNotification } from "./common/Notification/NotificationAction";
import { actionRequestRefreshToken } from "./RefreshAuth/RefreshAction";

import {
  makeAdminAgency,
  makeAdminInfo,
  makeAdminInfoValidate,
  makeAgencyData,
  makeAgentDetails,
  makeAgentInfo,
  makeArchiveAttachment,
  makeArchiveCoaching,
  makeArchiveCoachingGetById,
  makeArchiveJFV,
  makeArchiveJfvGetById,
  makeArchiveMeeting,
  makeArchiveMeetingGetById,
  makeAssignmentList,
  makeRecordAcknowledgementList,
  makeParticipant,
  makeCoachingGetById,
  makeCreateCoaching,
  makeCreateJFV,
  makeCreateMeeting,
  makeGetAssignmentFrom,
  makeGetAssignmentTo,
  makeGetMenu,
  makeGetNotification,
  makeJFVGetById,
  makeLeaderAssignment,
  makeLeaderJFVOverview,
  makeLeaderList,
  makeManageCoaching,
  makeManageJFV,
  makeManageMeeting,
  makeMeetingGetAbsentUpload,
  makeMeetingGetById,
  makeMeetingGetParticipantLists,
  makePolicyDetail,
  makeSubmitAssignment,
  makeSubmitRecordAcknowledgement,
  makeUpdateCoaching,
  makeUpdateJFV,
  makeUpdateMeeting,
  makeUploadCoaching,
  makeUploadCoachingForm,
  makeUploadMeeting,
  makeUploadMeetingForm,
  makeValidate,
  makeValidatePolicy,
  makeValidateTiming,
  makeDeleteCoaching,
  makeDeleteMeeting,
  makeDeleteJFV
} from "@store/selectors";
import { actionRequestDeleteCoaching, actionRequestDeleteCoachingInit } from "./Coaching/CoachingDelete/CoachingDeleteAction";
import { actionRequestDeleteJFV, actionRequestDeleteJFVInit } from "./JFV/JFVDelete/JFVDeleteAction";
import { actionRequestDeleteMeeting, actionRequestDeleteMeetingInit } from "./Meeting/MeetingDelete/MeetingDeleteAction";

const mapStateToProps = (state) => ({
  lang: state.lang,
  loader: state.loader,
  auth: state.auth,

  agentInfo: makeAgentInfo(state),
  adminInfo: makeAdminInfo(state),
  adminInfoValidate: makeAdminInfoValidate(state),
  agencyData: makeAgencyData(state),
  adminAgency: makeAdminAgency(state),

  agentDetails: makeAgentDetails(state),

  manageCoaching: makeManageCoaching(state),
  manageMeeting: makeManageMeeting(state),

  leaderJFVOverview: makeLeaderJFVOverview(state),

  archiveAttachment: makeArchiveAttachment(state),

  // HOME
  menu: makeGetMenu(state),
  validate: makeValidate(state),
  getNotification: makeGetNotification(state),

  // COACHING SUBMITTED
  updateCoaching: makeUpdateCoaching(state),
  coachingGetById: makeCoachingGetById(state),
  meetingParticipantLists: makeMeetingGetParticipantLists(state),

  // DELETE COACHING
  deleteCoaching: makeDeleteCoaching(state),

  // COACHING ARCHIVE
  archiveCoaching: makeArchiveCoaching(state),
  archiveCoachingGetById: makeArchiveCoachingGetById(state),

  // JFV ARCHIVE
  archiveJFV: makeArchiveJFV(state),
  archiveJfvGetById: makeArchiveJfvGetById(state),

   // DELETE JFV
   deleteJFV: makeDeleteJFV(state),

  // MEETING ARCHIVE
  archiveMeeting: makeArchiveMeeting(state),
  archiveMeetingGetById: makeArchiveMeetingGetById(state),

  // LEADER ASSIGNMENT
  leaderList: makeLeaderList(state),
  leaderAssignment: makeLeaderAssignment(state),

  // EACKNOWLEDGEMENT
  adminAgencyList: makeAdminAgency(state),
  assignmentList: makeAssignmentList(state),
  submitAssignment: makeSubmitAssignment(state),
  getAssignmentFrom: makeGetAssignmentFrom(state),
  getAssignmentTo: makeGetAssignmentTo(state),

  //RECORDACKNOWLEDGEMENT
  recordAcknowledgementList: makeRecordAcknowledgementList(state),
  submitRecordAcknowledgement: makeSubmitRecordAcknowledgement(state),

  // RECORDS MONITORING
  manageJFV: makeManageJFV(state),

  // COACHING SAVED
  valTiming: makeValidateTiming(state),
  validatePolicy: makeValidatePolicy(state),
  uploadCoaching: makeUploadCoaching(state),
  uploadCoachingForm: makeUploadCoachingForm(state),

  // COACHING ELECTRONIC FORM
  createCoaching: makeCreateCoaching(state),

  // JFV ELECTRONIC FORM
  createJFV: makeCreateJFV(state),
  policyDetail: makePolicyDetail(state),

  // JFV DELETED
  updateJFV: makeUpdateJFV(state),
  jfvGetById: makeJFVGetById(state),

  // MEETING ELECTRONIC FORM
  createMeeting: makeCreateMeeting(state),
  updateMeeting: makeUpdateMeeting(state),
  uploadMeeting: makeUploadMeeting(state),
  uploadMeetingForm: makeUploadMeetingForm(state),
  meetingGetAbsentUpload: makeMeetingGetAbsentUpload(state),
  participant: makeParticipant(state),

  // MEETING DELETED
  meetingGetById: makeMeetingGetById(state),

  // DELETE COACHING
  deleteMeeting: makeDeleteMeeting(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatchRefreshToken: () => dispatch(actionRequestRefreshToken()),
  dispatchReqAuthInit: () => dispatch(actionRequestAuthTokenInit()),
  dispatchMenuInit: () => dispatch(actionRequestGetMenuInit()),
  dispatchGetMenu: () => dispatch(actionRequestGetMenu()),

  dispatchReqAgencyDataInit: () => dispatch(actionRequestAgencyInit()),
  dispatchReqAgentInfoInit: () => dispatch(actionRequestAgentInfoInit()),

  dispatchReqAdminAgency: (agencyStatus) => dispatch(actionRequestAdminAgency(agencyStatus)),
  dispatchReqAgencyData: (agentCode) => dispatch(actionRequestAgency(agentCode)),
  dispatchReqAgentInfo: (agentCode) => dispatch(actionRequestAgentInfo(agentCode)),

  dispatchReqAdminInfo: (code) => dispatch(actionRequestAdminInfo(code)),
  dispatchReqAdminInfoInit: () => dispatch(actionRequestAdminInfoInit()),

  dispatchReqAdminInfoValidate: (code) => dispatch(actionRequestAdminInfoValidate(code)),
  dispatchReqAdminInfoValidateInit: () => dispatch(actionRequestAdminInfoValidateInit()),

  dispatchManageCoachingInit: () => dispatch(actionManageCoachingInit()),
  dispatchManageCoaching: (payload) => dispatch(actionRequestManageCoaching(payload)),

  dispatchReqAgentDetailsInit: () => dispatch(actionRequestAgentDetailsInit()),
  dispatchReqAgentDetails: (payload) => dispatch(actionRequestAgentDetails(payload)),

  dispatchManageMeetingInit: () => dispatch(actionManageMeetingInit()),
  dispatchManageMeeting: (payload) => dispatch(actionRequestManageMeeting(payload)),

  dispatchReqLeaderJFVOverviewInit: () => dispatch(actionRequestLeaderJFVOverviewInit()),
  dispatchReqLeaderJFVOverview: (payload) => dispatch(actionRequestLeaderJFVOverview(payload)),

  dispatchReqDownloadArchiveAttachment: (seqId) =>
    dispatch(actionRequestDownloadArchiveAttachment(seqId)),
  dispatchReqDownloadArchiveAttachmentInit: () =>
    dispatch(actionRequestDownloadArchiveAttachmentInit()),

  // HOME
  dispatchManageJFVInit: () => dispatch(actionManageJFVInit()),
  dispatchReqArchiveCoachingInit: () => dispatch(actionReqArchiveCoachingInit()),
  dispatchReqArchiveJFVInit: () => dispatch(actionReqArchiveJFVInit()),
  dispatchGetNotification: () => dispatch(actionRequestNotification()),

  // COACHING SUBMITTED
  dispatchCoachingGetById: (id) => dispatch(actionRequestCoachingById(id)),
  dispatchCoachingDownloadDoc: (id) => dispatch(actionRequestCoachingDownloadDoc(id)),
  dispatchCoachingDownloadDocForm: (id) => dispatch(actionRequestCoachingDownloadDocForm(id)),
  dispatchGetMeetingParticipantLists: (payload) =>
    dispatch(actionRequestGetMeetingParticipantLists(payload)),
  dispatchGetMeetingParticipantListsInit: () =>
    dispatch(actionRequestGetMeetingParticipantListsInit()),

  // COACHING ARCHIVE
  dispatchReqArchiveCoaching: (payload) => dispatch(actionReqArchiveCoaching(payload)),
  dispatchReqArchiveCoachingGetById: (id) => dispatch(actionRequestCoachingArchiveById(id)),
  dispatchReqArchiveCoachingGetByIdInit: () => dispatch(actionRequestCoachingArchiveByIdInit()),
  dispatchReqArchiveCoachingExport: (payload) => dispatch(actionReqArchiveCoachingExport(payload)),

  // COACHING SAVED
  dispatchUpdateCoachingInit: () => dispatch(actionUpdateCoachingInit()),
  dispatchCoachingUploadInit: () => dispatch(actionCoachingUploadInit()),
  dispatchUpdateCoaching: (payloadNewCoaching) =>
    dispatch(actionRequestUpdateCoaching(payloadNewCoaching)),
  dispatchCoachingUpload: (payload) => dispatch(actionRequestCoachingUpload(payload)),

  dispatchCoachingDelete: (payload) => dispatch(actionRequestDeleteCoaching(payload)),
  dispatchCoachingDeleteInit: () => dispatch(actionRequestDeleteCoachingInit()),

  dispatchValidateTimingInit: () => dispatch(actionValidateTimingInit()),
  dispatchValidateTiming: (payload) => dispatch(actionRequestValidateTiming(payload)),

  dispatchValidatePolicyInit: () => dispatch(actionValidatePolicyInit()),
  dispatchValidatePolicy: (payload) => dispatch(actionRequestValidatePolicy(payload)),

  dispatchValidateAddPolicyInit: () => dispatch(actionRequestValidateAddPolicyInit()),
  dispatchValidateAddPolicy: (payload) => dispatch(actionRequestValidateAddPolicy(payload)),

  dispatchCoachingUploadFormInit: () => dispatch(actionRequestCoachingUploadFormInit()),
  dispatchCoachingUploadForm: (payload) => dispatch(actionRequestCoachingUploadForm(payload)),

  // COACHING ELECTRONIC FORM
  dispatchCreateCoaching: (payload) => dispatch(actionRequestCreateCoaching(payload)),
  dispatchCreateCoachingInit: () => dispatch(actionCreateCoachingInit()),

  // JFV ARCHIVE
  dispatchReqArchiveJFV: (payload) => dispatch(actionReqArchiveJFV(payload)),
  dispatchReqArchiveJfvGetById: (id) => dispatch(actionRequestJfvArchiveById(id)),
  dispatchReqArchiveJfvGetByIdInit: () => dispatch(actionRequestJfvArchiveByIdInit()),
  dispatchReqArchiveJFVExport: (payload) => dispatch(actionReqArchiveJFVExport(payload)),
  dispatchReqArchiveJFVDownloadDoc: (id) => dispatch(actionReqArchiveJFVDownloadDoc(id)),

  dispatchJfvDelete: (payload) => dispatch(actionRequestDeleteJFV(payload)),
  dispatchJfvDeleteInit: () => dispatch(actionRequestDeleteJFVInit()),

  // MEETING ARCHIVE
  dispatchReqArchiveMeeting: (payload) => dispatch(actionReqArchiveMeeting(payload)),
  dispatchReqArchiveMeetingInit: () => dispatch(actionReqArchiveMeetingInit()),
  dispatchReqArchiveMeetingGetById: (id) => dispatch(actionRequestMeetingArchiveById(id)),
  dispatchReqArchiveMeetingGetByIdInit: () => dispatch(actionRequestMeetingArchiveByIdInit()),
  dispatchReqArchiveMeetingExport: (payload) => dispatch(actionReqArchiveMeetingExport(payload)),

  // LEADER ASSIGNMENT
  dispatchGetLeaderList: (agentCode) => dispatch(actionRequestLeaderList(agentCode)),
  dispatchPostLeaderAssignment: (payload) => dispatch(actionRequestLeaderAssignment(payload)),
  dispatchPostLeaderAssignmentInit: () => dispatch(actionRequestLeaderAssignmentInit()),

  // EACKNOWLEDGEMENT
  dispatchGetAssignmentList: (agencyCode) => dispatch(actionRequestAssignmentList(agencyCode)),
  dispatchGetAgencyList: () => dispatch(actionRequestAdminAgency()),
  dispatchSubmitAssignment: (payload) => dispatch(actionRequestSubmitAssignment(payload)),
  dispatchSubmitAssignmentInit: () => dispatch(actionRequestSubmitAssignmentInit()),
  dispatchGetAssignmentFrom: (payload) => dispatch(actionRequestGetAssignmentFrom(payload)),
  dispatchGetAssignmentFromInit: () => dispatch(actionRequestGetAssignmentFromInit()),
  dispatchGetAssignmentTo: (payload) => dispatch(actionRequestGetAssignmentTo(payload)),
  dispatchGetAssignmentToInit: () => dispatch(actionRequestGetAssignmentToInit()),

  //RecordAcknowledgement
  dispatchGetRecordAcknowledgementList: (agencyCode) => dispatch(actionRequestRecordAcknowledgementList(agencyCode)),
  dispatchSubmitRecordAcknowledgementInit: () => dispatch(actionRequestSubmitRecordAcknowledgement()),
  dispatchSubmitRecordAcknowledgement: (payload) => dispatch(actionRequestSubmitRecordAcknowledgement(payload)),

  // RECORDS MONITORING
  dispatchManageJFV: (payload) => dispatch(actionRequestManageJFV(payload)),
  dispatchCoachingGetByIdInit: () => dispatch(actionRequestCoachingByIdInit()),
  dispatchJFVGetByIdInit: () => dispatch(actionRequestJFVByIdInit()),
  dispatchMeetingGetByIdInit: () => dispatch(actionRequestMeetingByIdInit()),

  dispatchMeetingDelete: (payload) => dispatch(actionRequestDeleteMeeting(payload)),
  dispatchMeetingDeleteInit: () => dispatch(actionRequestDeleteMeetingInit()),

  // JFV ELECTRONIC FORM
  dispatchCreateJFV: (payloadNewJFV) => dispatch(actionRequestCreateJFV(payloadNewJFV)),
  dispatchPolicyDetail: (data) => dispatch(actionRequestPolicyDetail(data)),
  dispatchAddPolicyDetail: (data) => dispatch(actionRequestAddPolicyDetail(data)),
  dispatchCreateJFVInit: () => dispatch(actionCreateJFVInit()),
  dispatchPolicyDetailInit: () => dispatch(actionPolicyDetailInit()),
  dispatchAddPolicyDetailInit: () => dispatch(actionAddPolicyDetailInit()),
  dispatchCalculateJFVScoreInit: () => dispatch(actionRequestCalculateJFVScoreInit()),
  dispatchUploadOwnJFV: (payload) => dispatch(actionReqOwnJFVUpload(payload)),

  // JFV DELETED
  dispatchUpdateJFV: (payloadNewJFV) => dispatch(actionRequestUpdateJFV(payloadNewJFV)),
  dispatchUpdateJFVInit: () => dispatch(actionUpdateJFVInit()),
  dispatchJFVGetById: (id) => dispatch(actionRequestJFVById(id)),
  dispatchJfvDownloadDoc: (id) => dispatch(actionRequestJfvDownloadDocForm(id)),

  // MEETING ELECTRONIC FORM
  dispatchCreateMeeting: (payloadNewMeeting) =>
    dispatch(actionRequestCreateMeeting(payloadNewMeeting)),
  dispatchCreateMeetingInit: () => dispatch(actionCreateMeetingInit()),
  dispatchMeetingUploadInit: () => dispatch(actionMeetingUploadInit()),
  dispatchMeetingUpload: (payload) => dispatch(actionRequestMeetingUpload(payload)),
  dispatchSetAttendances: (payload) => dispatch(setAttandances(payload)),
  dispatchSetDataAgentLists: (payload) => dispatch(setDataAgentLists(payload)),
  dispatchSetDataAgentTier3Lists: (payload) => dispatch(setDataAgentTier3Lists(payload)),
  dispatchSetDataAgentTier2Lists: (payload) => dispatch(setDataAgentTier2Lists(payload)),
  dispatchMeetingAbsentUpload: (payload) => dispatch(actionRequestMeetingAbsentUpload(payload)),
  dispatchMeetingAbsentUploadInit: () => dispatch(actionMeetingAbsentUploadInit()),
  dispatchMeetingGetAbsentUploadInit: () => dispatch(actionRequestMeetingGetAbsentUploadInit()),
  dispatchMeetingUploadForm: (payload) => dispatch(actionRequestMeetingUploadForm(payload)),
  dispatchMeetingUploadFormInit: () => dispatch(actionRequestMeetingUploadFormInit()),

  // MEETING DELETED
  dispatchMeetingGetById: (id) => dispatch(actionRequestMeetingById(id)),
  dispatchMeetingGetAbsentUpload: (id) => dispatch(actionRequestMeetingGetAbsentUpload(id)),
  dispatchDownloadMeetingAbsent: (payload) => dispatch(actionRequestDownloadMeetingAbsent(payload)),
  dispatchMeetingDownloadDoc: (id) => dispatch(actionRequestMeetingDownloadDoc(id)),
  dispatchMeetingDownloadDocForm: (id) => dispatch(actionRequestMeetingDownloadDocForm(id)),

  // MEETING SAVED
  dispatchUpdateMeetingInit: () => dispatch(actionUpdateMeetingInit()),
  dispatchUpdateMeeting: (payload) => dispatch(actionRequestUpdateMeeting(payload))
});

export { mapDispatchToProps, mapStateToProps };
