import axios from "axios";

import { BASE_URL_API } from "@utils/Constants";
import { errorHandler, requestHandler, successHandler } from "@network/interceptors";
import Cookies from "js-cookie";

// console.log(`API URL: ${BASE_URL_API}`);
const auth = Cookies.get("token");
const config = {
  baseURL: `${BASE_URL_API}`,
  headers: {
    "Content-Type": "application/json",
    "x-ior-auth": auth
  }
};

//add your BASE_URL to Constants file
export const axiosInstance = axios.create(config);

// Handle request process
axiosInstance.interceptors.request.use((request) => requestHandler(request));
// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
