import * as Types from "./JFVDownloadDocFormTypes";

//Replace action name and update action types
export const actionRequestJfvDownloadDocFormInit = () => {
  return {
    type: Types.REQUEST_DOWNLOAD_JFV_DOC_FORM_INIT
  };
};

export const actionRequestJfvDownloadDocForm = (id) => {
  return {
    type: Types.REQUEST_DOWNLOAD_JFV_DOC_FORM,
    id
  };
};

export const actionReceiveJfvDownloadDocFormSuccess = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_JFV_DOC_FORM_SUCCESS,
    data
  };
};

export const actionReceiveJfvDownloadDocFormError = (data) => {
  return {
    type: Types.RECEIVE_DOWNLOAD_JFV_DOC_FORM_ERROR,
    data
  };
};
