import { axiosInstance } from "@network/apis";
import Auth from "@utils/Auth";

// Replace endpoint and change api name
const apiGetValidateTiming = async (payload) => {
  const auth = Auth.isValidate();
  const { id, startDate, endDate, startTime, endTime } = payload;

  const result = await axiosInstance.post(
    `/common/validateTiming`,
    {
      id,
      startDate,
      endDate,
      startTime,
      endTime
    },
    {
      headers: {
        "x-ior-auth": auth
      }
    }
  );
  return result;
};

export default apiGetValidateTiming;
