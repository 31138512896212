import * as Types from "./MeetingUpdateTypes";

//Replace action name and update action types
export const actionUpdateMeetingInit = () => {
  return {
    type: Types.UPDATE_MEETING_INIT
  };
};

export const actionRequestUpdateMeeting = (payload) => {
  return {
    type: Types.UPDATE_MEETING_REQUEST,
    payload
  };
};

export const actionReceiveUpdateMeetingSuccess = (data) => {
  return {
    type: Types.UPDATE_MEETING_RECEIVE_SUCCESS,
    data
  };
};

export const actionReceiveUpdateMeetingError = (data) => {
  return {
    type: Types.UPDATE_MEETING_RECEIVE_ERROR,
    data
  };
};
