import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetPolicyDetail = async (data) => {
  const auth = Cookies.get("token");
  let config = {};
  if (auth) {
    config = {
      headers: {
        "x-ior-auth": auth
      }
    };
  }
  const result = await axiosInstance.post(`/common/getPolicyDetails`, data, config);
  return result;
};

export default apiGetPolicyDetail;
