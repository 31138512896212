import * as types from "./LeaderAssignmentTypes";

//Replace action name and update action types
export const actionRequestLeaderAssignmentInit = () => {
  return {
    type: types.REQUEST_LEADER_ASSIGNMENT_INIT
  };
};

export const actionRequestLeaderAssignment = (payload) => {
  const {
    startDate,
    endDate,
    alterAgentLeaderCode,
    alterAgencyCode,
    agentLeaderCode,
    agencyCode,
    type
  } = payload;
  return {
    type: types.REQUEST_LEADER_ASSIGNMENT,
    payload: {
      startDate,
      endDate,
      alterAgentLeaderCode,
      alterAgencyCode,
      agentLeaderCode,
      agencyCode,
      type
    }
  };
};

export const actionReceiveLeaderAssignmentSuccess = (data) => {
  return {
    type: types.RECEIVE_LEADER_ASSIGNMENT_SUCCESS,
    data
  };
};

export const actionReceiveLeaderAssignmentError = (data) => {
  return {
    type: types.RECEIVE_LEADER_ASSIGNMENT_ERROR,
    data
  };
};
