import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ArchiveJFVDownloadDocAction";
import apiArchiveJFVDownloadDoc from "./ArchiveJFVDownloadDocApis";
import * as Types from "./ArchiveJFVDownloadDocTypes";

// Replace with your sagas
export function* archiveJFVDownloadDoc(action) {
  try {
    const response = yield call(apiArchiveJFVDownloadDoc, action.id);
    yield put(Actions.actionRecArchiveJFVDownloadDocSuccess(response));
  } catch (err) {
    yield put(Actions.actionRecArchiveJFVDownloadDocError(err.response));
  }
}

export function* ArchiveJFVDownloadDoc() {
  yield takeLatest(Types.REQUEST_ARCHIVE_JFV_DOWNLOAD_DOC, archiveJFVDownloadDoc);
}
