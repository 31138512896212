import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetAdminAgency = async (agencyStatus) => {
  const auth = Cookies.get("token");
  const agentCode = Cookies.get("login_id");
  let data = {};
  let headers = {};
  data["companyCode"] = "011";
  if (agencyStatus) data["status"] = agencyStatus;
  if (agentCode !== "") data["agentCode"] = agentCode;
  if (auth)
    headers = {
      "x-ior-auth": auth
    };
  return await axiosInstance.post(`/common/getAgencyList`, data, {
    headers
  });
};

export default apiGetAdminAgency;
