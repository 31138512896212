import * as types from "./ArchiveMeetingExportTypes";

export const INITIAL_STATE = {
  success: false
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_ARCHIVE_MEETING_EXPORT_INIT:
      return INITIAL_STATE;
    case types.RECEIVE_ARCHIVE_MEETING_EXPORT_SUCCESS:
      return {
        ...action,
        success: true
      };
    case types.RECEIVE_ARCHIVE_MEETING_EXPORT_ERROR:
      return {
        success: false
      };
    default:
      return state;
  }
};
