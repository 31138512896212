import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiMeetingUpload = async (payload) => {
  let data = new FormData();
  data.append("file", payload.file);
  data.append("id", payload.id);
  const auth = Cookies.get("token");
  let config;
  if (auth) {
    config = {
      headers: {
        "x-ior-auth": auth,
        "Content-Type": "multipart/form-data"
      }
    };
  }
  const result = await axiosInstance.post(`/meeting/upload`, data, config);
  return result;
};

export default apiMeetingUpload;
