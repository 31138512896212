import Auth from "@utils/Auth";
import jsCookie from "js-cookie";
import React, { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";

export const useHandleIdle = (props) => {
  const [sessionExpired, setSessionExpired] = React.useState(false);

  const {
    dispatchReqAuthInit,
    dispatchMenuInit,
    dispatchReqAgencyDataInit,
    dispatchReqAgentInfoInit
  } = props;

  const handleOnIdle = () => {
    Auth.signOut();
    dispatchReqAuthInit();
    dispatchMenuInit();
    dispatchReqAgencyDataInit();
    dispatchReqAgentInfoInit();
    setSessionExpired(true);
  };

  //
  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    crossTab: true,
    syncTimers: 200,
    name: "logout-timer"
  });

  return {
    sessionExpired
  };
};
