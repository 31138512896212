import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./LeaderAssignmentActions";
import apiPostLeaderAssignment from "./LeaderAssignmentApis";
import * as Types from "./LeaderAssignmentTypes";

// Replace with your sagas
export function* postLeaderAssignment(action) {
  try {
    const response = yield call(apiPostLeaderAssignment, action.payload);
    yield put(Actions.actionReceiveLeaderAssignmentSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveLeaderAssignmentError(err.response.data));
  }
}

export function* LeaderAssignment() {
  yield takeLatest(Types.REQUEST_LEADER_ASSIGNMENT, postLeaderAssignment);
}
