import * as types from "./ArchiveMeetingExportTypes";

//Replace action name and update action types
export const actionReqArchiveMeetingExportInit = () => {
  return {
    type: types.REQUEST_ARCHIVE_MEETING_EXPORT_INIT
  };
};

export const actionReqArchiveMeetingExport = (payload) => {
  const { startDate, endDate, code, status } = payload;
  return {
    type: types.REQUEST_ARCHIVE_MEETING_EXPORT,
    payload: {
      code,
      startDate,
      endDate,
      status
    }
  };
};

export const actionRecArchiveMeetingExportSuccess = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_MEETING_EXPORT_SUCCESS,
    data
  };
};

export const actionRecArchiveMeetingExportError = (data) => {
  return {
    type: types.RECEIVE_ARCHIVE_MEETING_EXPORT_ERROR,
    data
  };
};
