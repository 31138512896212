import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

/**
 * Async await Function Api Create Coaching
 * @function apiCreateCoaching @returns AxiosResponse
 */

// Replace endpoint and change api name
const apiCreateCoaching = async (payload) => {
  const auth = Cookies.get("token");
  let config = {};
  if (auth) config = { headers: { "x-ior-auth": auth } };
  const {
    type,
    twelveWeekCoaching,
    coachId,
    coachName,
    agencyCode,
    agencyName,
    coacheeId,
    coacheeName,
    date,
    startTime,
    endTime,
    topic,
    reviewPrevSession,
    activityManagement,
    agenda,
    keyCoachingPoints,
    actionPlan,
    coachFeedback,
    coacheeFeedback,
    status
  } = payload;

  const result = await axiosInstance.post(
    `/coaching/create`,
    {
      type,
      "12weekCoaching": twelveWeekCoaching,
      coachId,
      coachName,
      agencyCode,
      agencyName,
      coacheeId,
      coacheeName,
      date,
      startTime,
      endTime,
      topic,
      reviewPrevSession,
      activityManagement,
      agenda,
      keyCoachingPoints,
      actionPlan,
      coachFeedback,
      coacheeFeedback,
      status,
      deletedBy: ""
    },
    config
  );
  return result;
};

export default apiCreateCoaching;
