import { axiosInstance } from "@network/apis";
import Cookies from "js-cookie";

// Replace endpoint and change api name
const apiGetAgentDetails = async (payload) => {
  const { agencyCode, month, year } = payload;
  const auth = Cookies.get("token");
  let data = {};
  let config = {};
  data["month"] = month;
  data["year"] = year;
  data["companyCode"] = "011";
  if (agencyCode) data["agencyCode"] = agencyCode;
  if (auth) {
    config = {
      headers: {
        "x-ior-auth": auth
      }
    };
  }
  const result = await axiosInstance.post(`/tnc/getAgentDetailsTnC`, data, config);
  return result;
};

export default apiGetAgentDetails;
