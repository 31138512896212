import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./CreateJFVAction";
import apiCreateJFV from "./CreateJFVApis";
import * as Types from "./CreateJFVTypes";

// Replace with your sagas
export function* createJFV(action) {
  try {
    const response = yield call(apiCreateJFV, action.payload);
    yield put(Actions.actionReceiveCreateJFVSuccess(response.data));
  } catch (err) {
    yield put(Actions.actionReceiveCreateJFVError(err.response.data));
  }
}

export function* CreateJFV() {
  yield takeLatest(Types.CREATE_JFV_REQUEST, createJFV);
}
